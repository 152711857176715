
<template>
  <div>
    <div v-if="!small">
      <h1>Tarife-Manager</h1>
      <div v-for="(tarif, key) in adtgTarife" :key="key">
        <v-container class="grey lighten-4 ma-0 pa-0 mb-3">
          <v-row dense align="center">
            <v-col sm="1" class="text-center" style="max-width: 4em">
              <v-chip :color="getColor(tarif.template_status)" dark>{{
                tarif.tarif_id
              }}</v-chip>
            </v-col>
            <v-col>
              <h3 class="tarif-link" @click="redirectToTarif(tarif.tarif_id)">
                {{ tarif.name }}
              </h3>
              <v-container fluid class="ma-0 pa-0">
                <v-row align="center" justify="start" dense>
                  <v-col sm="" v-for="(prop, key) in enabledProps" :key="key">
                    <v-checkbox
                      v-model="adtgTarifeStatus[tarif.tarif_id][prop.position]"
                      @change="
                        updateTemplateStatus($event, tarif, prop.position)
                      "
                      :label="`${prop.text}`"
                    ></v-checkbox>
                  </v-col>
                  <v-col sm="1" class="grey--text text--lighten-1">
                    {{ tarif.template_status }}
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-text-field
        label="tarif_id to add"
        v-model="ADD_tarif_id"
      ></v-text-field>
      <v-btn @click="addAdtgTarif()"> Add </v-btn>
    </div>
    <div class="small-view" v-if="small && tarifId && adtgTarife.length">
      <h3>Aufbaustatus</h3>
      <h2
        class="pa-2 subtitle-2"
        :class="getColor(getSelectedTarif().template_status)"
      >
        {{ getSelectedTarif().name }}
      </h2>
      <span v-for="(prop, key) in enabledProps" :key="key">
        <v-checkbox
          v-model="adtgTarifeStatus[tarifId][prop.position]"
          @change="
            updateTemplateStatus($event, getSelectedTarif(), prop.position)
          "
          :label="`${prop.text}`"
        ></v-checkbox>
      </span>
    </div>
  </div>
</template>

<script>
import BinaryHelper from "../BinaryHelper";
export default {
  name: "tarife-manager",
  components: {},
  created() {
    this.binaryHelper = new BinaryHelper();
    this.$store.dispatch("antragdruck/getAdtgTarife").then(() => {
      this.$store.dispatch("antragdruck/getWtbTarife").then(() => {});
    });
  },
  props: {
    small: Boolean,
    tarifId: Number,
  },
  data: function () {
    return {
      // number of binary settings for tarifStatus
      ADD_tarif_id: null,
      enabledProps: [
        // { position: 0, text: "Prop 0" },
        // { position: 1, text: "Prop 1" },
        // { position: 2, text: "Prop 2" },
        // { position: 3, text: "Prop 3" },
        { position: 4, text: "Bilder" },
        { position: 5, text: "Alles OK" },
        { position: 6, text: "DruckLabel " },
        { position: 7, text: "Alle Seiten " },
        { position: 8, text: "BAP" },
        { position: 9, text: "VM-ID" },
        { position: 10, text: "KundeSign" },
      ],
      statusBitArrayLength: 8,
      checkbox: false,
      ADD_TARIF_VISIBLE: false,
      template_status_select_fields: [
        {
          id: 0,
          status_label: "in bearbeitung",
        },
        {
          id: 1,
          status_label: "Fertig",
        },
      ],
      max25chars: (v) => v.length <= 25 || "Input too long!",
      pagination: {},
      selected_tarif: null,
      addTarif: {
        tarif_id: null,
      },
      headers: [
        {
          text: "tarif_id",
          align: "left",
          sortable: true,
          value: "tarif_id",
        },
        { text: "Template status", value: "template_status" },
        //   { text: 'Name', value: 'name' },
        //   { text: 'Comment', value: 'comment' },
        //   { text: 'Update date', value: 'last_update_date' },
      ],
    };
  },
  mounted() {},
  methods: {
    redirectToTarif(tarif_id) {
      localStorage.tarif_id = tarif_id;
      localStorage.page_id = 0;
      let routeData = this.$router.resolve({ name: "Home" });
      window.open(routeData.href, "_blank");
      // this.$router.push({ path: '/', target: '_blank' })
    },
    getSelectedTarif() {
      if (!this.tarifId) {
        return {};
      }
      let tarife = this.adtgTarife.filter(
        (tarif) => tarif.tarif_id == this.tarifId
      );
      return tarife[0];
    },
    computeCheckboxValue(boolean_value) {
      let result = boolean_value == "1" ? true : false;
      return result;
    },
    extractTemplateStatusPart(template_status, position) {
      let binary = this.binaryHelper.integerToBinary(template_status);
      return binary[position];
    },
    addAdtgTarif() {
      // this.$log.debug('addAdtgTarif', this.selected_tarif)
      if (!this.ADD_tarif_id) {
        return;
      }
      let wtbTarif = this.wtbTarife.tarife.filter(
        (tarif) => tarif.tarif_id == this.ADD_tarif_id
      );
      // this.$log.debug(wtbTarif);
      if (!wtbTarif[0]) {
        return;
      }
      let adtgTarif = {
        tarif_id: wtbTarif[0].tarif_id,
        name: wtbTarif[0].tarif_name_combined,
        template_status: 0,
        comment: "",
      };
      return this.$store.dispatch("antragdruck/addAdtgTarif", adtgTarif);
    },
    updateTemplateStatus($event, adtgTarif, position) {
      let statusArray = this.adtgTarifeStatus[adtgTarif.tarif_id];
      this.$log.debug("status array", statusArray);
      statusArray[position] = $event;
      adtgTarif.template_status = this.binaryHelper.booleanArrayToInteger(
        statusArray
      );
      this.$log.debug("adtgTarif.template_status", adtgTarif.template_status);
      this.$log.debug($event, adtgTarif, position);
      return this.$store.dispatch("antragdruck/updateAdtgTarif", adtgTarif);
    },
    getColor(template_status) {
      let complete_status = 2047;
      if (template_status == complete_status || template_status == 15)
        return "green";
      if (template_status == 0) return "red";
      if (template_status > 0) return "orange";
    },
  },
  computed: {
    adtgTarifeStatus() {
      let status = {};
      this.adtgTarife.forEach((tarif) => {
        status[tarif.tarif_id] = this.binaryHelper
          .integerToBinary(tarif.template_status)
          .split("");
        status[tarif.tarif_id] = status[tarif.tarif_id].map((el) =>
          el == "1" ? true : false
        );
      });
      // this.$log.debug('status', status);
      return status;
    },
    adtgTarife() {
      return this.$store.getters["antragdruck/adtgTarife"];
    },
    wtbTarife() {
      return this.$store.getters["antragdruck/wtbTarife"];
    },
  },
};
</script>

<style>
.tarif-link:hover {
  cursor: pointer;
}
</style>

