<template>
<v-container>
    <h1>Antragdruck Template-Generator</h1>
    <tarife-manager />
</v-container>
</template>

<script>
import TarifeManager from "../components/TarifeManager"
export default {
  name: "tarife-manager-page",
  components: {
    TarifeManager
  },
  created() {
  },
  data: function() {
    return {
    }
  },
};
</script>

<style scoped>
</style>

