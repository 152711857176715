<template>
  <div class="antrag_druck_template_generator_wrapper">
    <div>
      <!-- History: {{ this.antragDruckTemplate.history_file }} -->
      <!-- {{images}} -->
      {{ checktoken }}
      <div
        v-show="errors.length"
        v-for="(error, key) in errors"
        :key="`error-${key}`"
      >
        <v-alert color="red">{{ error }}</v-alert>
      </div>
    </div>
    <div v-if="APP_STARTED">
      <v-navigation-drawer
        ref="navigation-drawer"
        class="overflow-y-auto"
        id="navigation-drawer"
        width="550"
        hide-overlay
        temporary
        v-model="drawerAntragDruck"
        :app="true"
        right
        clipped
      >
        <div v-show="!HISTORY_MODE">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                <v-btn
                  @click="drawerAntragDruck = !drawerAntragDruck"
                  icon
                  text
                >
                  <v-icon>close</v-icon>
                </v-btn>
                <span v-if="selectedTarif">{{ selectedTarif.name }}</span>
                <!-- upload file indicator -->
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-btn @click="HISTORY_MODE = !HISTORY_MODE">History</v-btn>
            <v-btn @click="PDF_UPLOAD_VISIBLE = !PDF_UPLOAD_VISIBLE"
              >PDF UPLOAD</v-btn
            >
            <!-- <v-btn @click="PDF_UPLOAD_VISIBLE = !PDF_UPLOAD_VISIBLE">Edit Template Image</v-btn> -->
          </v-list-item>
          <v-list-item v-show="PDF_UPLOAD_VISIBLE">
            <v-progress-circular
              indeterminate
              v-show="FILE_IS_UPLOADING"
            ></v-progress-circular>
            <input type="file" class="form-control" @change="onFileChange" />
            <v-text-field
              v-model="UPLOAD_DOCUMENT_TYPE"
              label="Document Typ"
            ></v-text-field>
            <v-btn right @click="doFileUpload">Upload</v-btn>
          </v-list-item>

          <v-container
            v-if="template_load_complete && antragDruckTemplate.pages.length"
          >
            <!-- Control elements -->
            <!-- @mouseover="handleControlElementMouseover(index)" 
                        @mouseout="handleControlElementMouseout(index)"-->
            <!-- @focus.native="handleControlElementMouseover(index)"
                        @blur.native="handleControlElementMouseover(index)"-->
            <v-row
              dense
              align="center"
              justify="space-between"
              class="grey lighten-3"
            >
              <v-col>
                <v-textarea
                  label="Comment"
                  :value="antragDruckTemplate.comment"
                  @change="updateComment($event)"
                ></v-textarea>
                <v-select
                  small
                  :value="getLayoutImage()"
                  @change="updateLayoutImage($event)"
                  label="Layout image"
                  :items="antragImagesList"
                  menu-props="auto"
                ></v-select>
                <!-- <v-text-field 
                    label="Layout image"
                    :value="antragDruckTemplate.pages[page_id].layout_image"
                                @input="updateLayoutImage($event)"></v-text-field>-->
                <v-text-field
                  label="Default font size"
                  :value="antragDruckTemplate.pages[page_id].default_font_size"
                  @input="updateDefaultFontSize($event)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="pt-3"></v-divider>
            <v-row>
              <v-col>Elements:</v-col>
            </v-row>
            <v-card
              ma2
              v-for="(element, index) in elements"
              :key="`element-${index}`"
              :id="`element_${index}`"
              :class="{
                'control-element-selected-card': styleElementSelected(index),
                'mx-auto': true,
              }"
              :raised="styleElementSelected(index)"
              outlined
              dense
            >
              <!-- simple info -->
              <v-row
                dense
                align="start"
                justify="start"
                :class="{
                  blue: styleElementSelected(index),
                  'lighten-3': styleElementSelected(index),
                }"
                @mouseup.stop="elementMouseup(index)"
              >
                <v-col cols="3">
                  <span style="color: #616161">{{ element.type }}:&nbsp;</span>
                </v-col>
                <v-col cols="9">
                  <div
                    v-if="element.type == 'Variable' || element.type == 'Kreuz'"
                  >
                    <span style="color: black"
                      >{{ element.variable_name }}.{{
                        element.variable_field
                      }}&nbsp;</span
                    >
                  </div>
                  <div v-if="element.type == 'Text'">
                    {{ element.textLabel }}:&nbsp;
                    {{ element.text }}
                  </div>
                  <div v-if="element.type == 'DruckLabel'">
                    {{ element.text }}
                  </div>
                  <div v-if="element.conditions.length">
                    C: {{ element.conditions.length }}
                  </div>
                  <div style="color: #616161">
                    x: {{ element.x }}, y: {{ element.y }}
                  </div>
                </v-col>
              </v-row>
              <div v-if="index == selected_element_index">
                <v-row
                  dense
                  align="center"
                  justify="space-between"
                  :class="{
                    blue: styleElementSelected(index),
                    'lighten-4': true,
                  }"
                >
                  <v-col cols="2">
                    <span class="grey--text">#{{ index }}</span>
                  </v-col>
                  <v-col cols="8">
                    <!-- rechts edit -->
                    <v-select
                      small
                      :value="element.type"
                      @change="updateElement($event, index, 'type')"
                      label="Element type"
                      :items="elementsList"
                      append-outer-icon="data"
                      menu-props="auto"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-btn @click="deleteElement(index)" text icon>
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="4"
                    v-show="
                      element.type == 'Variable' ||
                      element.type == 'Kreuz' ||
                      element.type == 'Arrow'
                    "
                  >
                    <!-- v-model="element.variable_name" -->
                    <!-- :value="element.variable_name"
                                        @change="updateElement($event, index, 'variable_name')"-->
                    <!-- Variable Name -->
                    <v-select
                      :value="element.variable_name"
                      @change="updateElement($event, index, 'variable_name')"
                      :items="accesibleVariables"
                      label="Variable"
                    ></v-select>
                  </v-col>
                  <!-- v-model="element.variable_field" -->
                  <!-- Variable Field -->
                  <v-col cols="8">
                    <v-autocomplete
                      v-if="
                        element.type == 'Variable' ||
                        element.type == 'Kreuz' ||
                        element.type == 'Arrow'
                      "
                      label="Prop"
                      :value="element.variable_field"
                      @change="updateElement($event, index, 'variable_field')"
                      :items="variableKeys[element.variable_name]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="4"
                    v-show="
                      element.type == 'Variable' ||
                      element.type == 'Kreuz' ||
                      element.type == 'Arrow' ||
                      element.type == 'Text' ||
                      element.type == 'DruckLabel' ||
                      element.type == 'BAP' ||
                      element.type == 'VermittlerSign' ||
                      element.type == 'SignKunde' ||
                      element.type == 'SignKundeVP' ||
                      element.type == 'SignKundeDatum' ||
                      element.type == 'SignKundeOrt'
                    "
                    >1
                    <!-- v-model="element.variable_name" -->
                    <!-- :value="element.variable_name"
                                        @change="updateElement($event, index, 'variable_name')"-->
                    <!-- Variable Name -->
                    <!-- <v-text-field
                      :value="element.style.color"
                      @input="updateElementStyle($event, index, 'color')"
                      label="Color"
                    ></v-text-field>
                    <div v-for="(color, color_index) in colors" :key="color_index">
                      <span :style="{color: `rgb(${color.rgb[0]}, ${color.rgb[1]}, ${color.rgb[2]})`}" >{{ color.name }}</span>
                                        </div>-->
                    <v-select
                      @change="updateElementStyle($event, index, 'color')"
                      :items="colors"
                      label="Color"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="4"
                    v-show="
                      element.type == 'Variable' ||
                      element.type == 'Kreuz' ||
                      element.type == 'Arrow' ||
                      element.type == 'Text' ||
                      element.type == 'DruckLabel' ||
                      element.type == 'BAP' ||
                      element.type == 'SignKunde' ||
                      element.type == 'SignKundeVP' ||
                      element.type == 'SignKundeDatum' ||
                      element.type == 'SignKundeOrt'
                    "
                    >2
                    <!-- v-model="element.variable_name" -->
                    <!-- :value="element.variable_name"
                                        @change="updateElement($event, index, 'variable_name')"-->
                    <!-- Variable Name -->
                    <v-text-field
                      :value="element.style['font-size']"
                      @input="updateElementStyle($event, index, 'font-size')"
                      label="font-size (px)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="4"
                    v-show="
                      element.type == 'Variable' ||
                      element.type == 'Kreuz' ||
                      element.type == 'Arrow' ||
                      element.type == 'Text' ||
                      element.type == 'DruckLabel' ||
                      element.type == 'BAP' ||
                      element.type == 'SignKunde' ||
                      element.type == 'SignKundeVP' ||
                      element.type == 'SignKundeDatum' ||
                      element.type == 'SignKundeOrt'
                    "
                    >3
                    <v-checkbox
                      :value="element.style['font-weight']"
                      @change="updateElementStyle($event, index, 'font-weight')"
                      :label="`Bold`"
                    ></v-checkbox>
                  </v-col>
                  <!-- <v-col>
                    <v-text-field
                      :value="element.style['letter-spacing']"
                      @input="updateElementStyle($event, index, 'letter-spacing')"
                      label="letter-spacing"
                    ></v-text-field>
                                    </v-col>-->
                </v-row>
                <v-row dense v-show="element.type == 'Image'">
                  <v-col>
                    <b class="green--text">{{ element.imagetype }}</b>
                    <v-text-field
                      label="text"
                      :value="element.image"
                      @input="updateElement($event, index, 'image')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense v-show="element.type == 'Image'">
                  <v-col>
                    <!-- v-model="element.width" -->
                    <v-text-field
                      label="width"
                      :value="element.width"
                      @input="updateElement($event, index, 'width')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <!-- v-model="element.height" -->
                    <v-text-field
                      label="height"
                      :value="element.height"
                      @input="updateElement($event, index, 'height')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense v-if="element.type == 'Text'">
                  <v-col>
                    <!-- v-model="element.text" -->
                    <v-text-field
                      label="text"
                      :value="element.text"
                      @input="updateElement($event, index, 'text')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <!-- v-model="element.textLabel" -->
                    <v-text-field
                      label="textLabel"
                      :value="element.textLabel"
                      @input="updateElement($event, index, 'textLabel')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <!-- v-model="element.x" -->
                    <v-text-field
                      label="x"
                      :value="element.x"
                      @input="updateElement($event, index, 'x')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <!-- v-model="element.y" -->
                    <v-text-field
                      label="y"
                      :value="element.y"
                      @input="updateElement($event, index, 'y')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  v-show="element.conditions.length"
                  style="border-top: 2px solid white"
                >
                  <v-col>
                    <h2>Conditions:</h2>
                  </v-col>
                </v-row>

                <v-row
                  style="border-bottom: 2px solid white"
                  v-for="(condition, condition_index) in element.conditions"
                  :key="`condition-${condition_index}`"
                >
                  <v-col cols="5">
                    <!-- Variable Name -->
                    <v-select
                      v-model="condition.variable_name"
                      :items="accesibleVariables"
                      label="Variable"
                    ></v-select>
                  </v-col>
                  <!-- Variable Field -->
                  <v-col cols="6">
                    <v-autocomplete
                      label="Prop"
                      v-model="condition.variable_field"
                      :items="variableKeys[condition.variable_name]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      @click="deleteCondition(index, condition_index)"
                      icon
                      text
                      title="Delete Condition"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- condition type -->
                  <v-col>
                    <v-select
                      v-model="condition.type"
                      :items="conditionTypes"
                      label="Condition Type"
                    ></v-select>
                  </v-col>
                  <!-- compare value -->
                  <v-col
                    v-show="
                      condition.type == 'LesserThanValue' ||
                      condition.type == 'GreaterThanValue' ||
                      condition.type == 'EqualsValue' ||
                      condition.type == 'NotEqualsValue'
                    "
                  >
                    <v-text-field
                      label="Compare Value"
                      v-model="condition.compare_value"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="border-top: 2px solid white">
                  <v-col>
                    <v-btn @click="addCondition(index)" text>
                      add Condition
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-container>

          <div id="bottom-drawer">
            <h1>Bottom</h1>
          </div>
        </div>
        <div v-show="HISTORY_MODE">
          <h1>History</h1>
          <v-list>
            <v-list-item>
              <v-btn @click="HISTORY_MODE = !HISTORY_MODE"
                >Return to edit mode</v-btn
              >
            </v-list-item>
            <v-list-item
              v-for="(history, history_id) in historyElements.slice().reverse()"
              :key="`history-${history_id}`"
            >
              <a @click="restoreHistory(history.file)"
                >{{ history.date }} {{ history.time }}</a
              >
            </v-list-item>
          </v-list>
          <!-- {{ historyElements }} -->
        </div>
      </v-navigation-drawer>
      <!-- {{template_load_complete}}
            {{antragDruckLayoutImage}}-->
      <div
        v-show="
          template_load_complete && antragData.preis.preis_value == undefined
        "
      >
        <div v-for="(preis, key) in antragData.preiseList" :key="key">
          <span class="red--text subtitle-2" v-show="preis.preis_status == 2"
            >Old</span
          >
          {{ preis.preis_value }} &euro;
        </div>
      </div>
      <div>
        <strong>Letzte Abschlüsse</strong>
        <v-btn
          style="text-transform: none !important"
          text
          v-for="(abschluss, last_abschluss_index) in lastAbschluss"
          :key="last_abschluss_index"
          :title="abschlussTitle(abschluss)"
          :class="{
            blue: abschluss.abs_id == antragData.abschluss.abs_id,
          }"
          @click="selectAnbschluss(abschluss)"
        >
          <!-- abs_id: {{ abschluss.abs_id }} -->
          {{ abschluss.antragstellerv }}
          {{ abschluss.antragsteller }}
          <span v-if="abschluss.versicherungsnehmer">
            ( vp: {{ abschluss.vversicherungsnehmer }}
            {{ abschluss.versicherungsnehmer }} )
          </span>
          <!-- {{ abschluss.abs_created_date }} -->
        </v-btn>
      </div>
      <v-container>
        <v-row align="center" justify="end">
          <v-col v-if="template_load_complete">
            <v-btn
              class="blue lighten-2"
              @click="PREVIEW_LINKS_VISIBLE = !PREVIEW_LINKS_VISIBLE"
              >Preview</v-btn
            >
            <v-btn
              v-if="ergoTarife.includes(parseInt(selectedTarif.tarif_id))"
              class="green"
              @click="generateErgoTemplate()"
              >GENERATE Ergo Template</v-btn
            >
            <v-progress-circular
              indeterminate
              v-show="GENERATING_ERGO_TEMPLATE"
            ></v-progress-circular>
            <div v-if="ergoResponse !== null">
              status: {{ ergoResponse.status }} Tarif:
              {{ ergoResponse.ergoResponse.Tarif }} Dokumentinfo:
              {{ ergoResponse.ergoResponse.Dokumentinfo }}
              <a :href="ergoResponse.ergoPdfTemplateGlobalUrl" target="_blank"
                >Ergo template</a
              >
            </div>
            <v-btn class="green" @click="generateAntragAndBeiblaetter()"
              >GENERATE</v-btn
            >
          </v-col>
          <v-col sm="10">
            <h2 v-if="selectedTarif">
              <span class="grey--text">Antrag</span>
              {{ selectedTarif.name }}
            </h2>
          </v-col>
          <!-- {{getLocalStorage.tarif_id}}
                {{getLocalStorage.page_id}}
                    {{selectedTarif}}-->
        </v-row>
      </v-container>
      <!-- x: {{mouseX}}
        y: {{mouseY}}
            &nbsp;&nbsp;-->
      <div v-if="false">
        <v-progress-circular
          v-show="$store.getters['antragdruck/isLoading']"
          :value="loadingPercent"
        ></v-progress-circular>
      </div>
      <div v-show="ALL_GENERATED" class="green">Erfolgreich generiert</div>
      <div class="grey lighten-3">
        <div v-show="GENERATOR_STARTED || ALL_GENERATED">
          <div
            v-for="(antrag_druck_log, file_type) in $store.getters[
              'antragdruck/generated'
            ]"
            :key="file_type"
          >
            <span v-show="antrag_druck_log.status != 'OK'">{{
              file_type
            }}</span>
            <!-- links to generated files -->
            <!-- dist/antrag_druck/${aid}/${antrag_druck_log.filename}`" -->
            <span v-show="antrag_druck_log.status == 'OK'">
              <a target="_blank" :href="`${antrag_druck_log.fileGlobalUrl}`">{{
                file_type
              }}</a>
              &nbsp;&nbsp;
              <span class="green">done</span>
            </span>
          </div>
          <hr />
        </div>
      </div>

      <div v-show="PREVIEW_LINKS_VISIBLE" class="grey lighten-3 pa-3">
        <div v-if="selectedTarif">
          <v-btn
            text
            target="_blank"
            :href="`${apiUrl}antragdruckapi/generate_antrag_pdf/browser/${selectedTarif.tarif_id}/${abs_id}/${page_id}`"
            >Diese Seite</v-btn
          >&nbsp;&nbsp;
          <v-btn
            text
            target="_blank"
            class="green lighten-3"
            :href="`${apiUrl}antragdruckapi/generate_antrag_pdf/browser/${selectedTarif.tarif_id}/${abs_id}/full`"
            >Antrag</v-btn
          >&nbsp;&nbsp;
          <!-- {{ $store.getters['antragdruck/generated'] }} -->
          <!-- file_type: antrag|hinweis|clearment ... -->
          <!-- &nbsp;&nbsp;
            <a target="_blank" 
                :href="`${apiUrl}antragdruckapi/generate_antrag_pdf/file/${selectedTarif.tarif_id}/${abs_id}/full`">
                    Generate PDF</a>-->
          &nbsp;&nbsp;
          <v-btn
            text
            class="red lighten-3"
            target="_blank"
            :href="`http://dev.waizmanntabelle.de/antrag_druck/tarifmig19/${abs_id}/${
              selectedTarif.tarif_id
            }/0/0/${page_id + 1}`"
            >Old renderer</v-btn
          >
          <a
            :href="`http://flow.waizmanntabelle.de/dist/antrag_druck/${aid}/${abs_id}_antrag.pdf`"
            target="_blank"
            >Antrag generated old</a
          >
        </div>
        <div>
          <!-- :href="`${apiUrl}/antragdruckapi/generate_anschreiben_pdf/browser/${selectedTarif.tarif_id}/${abs_id}`" -->
          <v-btn
            text
            target="_blank"
            :href="`${wtbApiUrl}dokumente/generate_pdf/${abs_id}/anschreiben_zahn/browser`"
            >Anschreiben</v-btn
          >&nbsp;&nbsp;
          <!-- :href="`${apiUrl}/antragdruckapi/generate_clearment_pdf/browser/${selectedTarif.tarif_id}/${abs_id}`" -->
          <v-btn
            text
            target="_blank"
            :href="`${wtbApiUrl}dokumente/generate_pdf/${abs_id}/clearment/browser`"
            >Clearment</v-btn
          >&nbsp;&nbsp;
          <!-- :href="`${apiUrl}/antragdruckapi/generate_hinweis_pdf/browser/${selectedTarif.tarif_id}/${abs_id}`" -->
          <v-btn
            text
            target="_blank"
            :href="`${wtbApiUrl}dokumente/generate_pdf/${abs_id}/antraghinweise_zahn/browser`"
            >Hinweis</v-btn
          >&nbsp;&nbsp;
          <!-- :href="`${apiUrl}/antragdruckapi/generate_tarifbeschreibung_pdf/browser/${selectedTarif.tarif_id}/${abs_id}`" -->
          <v-btn
            text
            target="_blank"
            :href="`${wtbApiUrl}dokumente/generate_pdf/${abs_id}/tarifbeschreibung_zahn/browser`"
            >Tarifbeschreibung</v-btn
          >
        </div>
      </div>

      <!-- Kundedaten short -->
      <!-- hidden -->
      <!-- <div v-if="antragData.kunde && antragData.abschluss"
           class="grey lighten-2"
      >
        <strong>Selected abs_id: {{antragData.abschluss.abs_id}} </strong>
        {{antragData.kunde.antragstellerv}}
        {{antragData.kunde.antragsteller}}
        <span v-show="antragData.kunde.versicherungsnehmer">
          ( vp: {{ antragData.kunde.vversicherungsnehmer }}
          {{ antragData.kunde.versicherungsnehmer }} )
          <div>
            {{ antragData.kunde.strasse }}
            <br />
            {{ antragData.kunde.ort }}
          </div>
        </span>
            </div>-->

      <!-- <v-text-field label="tarif_id" v-model="tarif_id"></v-text-field> -->
      <!-- {{ antragDruckTemplate }}
            {{ backgroundImage }}-->
      <div class="grey lighten-3 pa-3" v-show="show_antrag_fragen">
        <a target="_blank" href="/doc/explore">DOCUMETATION</a>
        <h2>Antrag fragen</h2>
        <div
          v-for="(frage, index) in tarifFragen"
          :key="`tarif-frage-${index}`"
        >
          {{ frage.antrag_old }}
          {{ frage.antrag_text }}
        </div>
      </div>
      <v-toolbar
        v-show="loadingPercent == 100"
        absolute
        style="position: fixed; top: 1em"
      >
        <v-toolbar-items>
          <v-btn
            color="red"
            dark
            v-show="
              template_load_complete &&
              antragData.preis.preis_value == undefined
            "
            >PREIS EMPTY!!!!</v-btn
          >
          <v-btn
            icon
            text
            :class="{ red: !template_saved, 'lighten-4': true }"
            title="Save Antrag Template"
            @click="saveAntragDruckTemplate()"
          >
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn
            icon
            title="Edit elements"
            @click="drawerAntragDruck = !drawerAntragDruck"
          >
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn
            icon
            text
            title="Move Element"
            @click="setFunction('move')"
            :elevation="selectedFunction == 'move' ? 4 : 0"
            :class="{
              'function-selected': selectedFunction == 'move',
            }"
          >
            <v-icon>mdi-cursor-move</v-icon>
          </v-btn>
          <v-divider vertical></v-divider>
          <v-btn
            icon
            text
            title="Add Element"
            @click="setFunction('add')"
            :elevation="selectedFunction == 'add' ? 4 : 0"
            :class="{
              'function-selected': selectedFunction == 'add',
            }"
          >
            <v-icon>add</v-icon>
          </v-btn>
          <v-row class="ma-1">
            <v-col>
              <!-- oben select -->
              <v-select
                style="width: 100px"
                small
                dense
                v-model="elementToInsert"
                label="Type"
                :items="elementsList"
                menu-props="auto"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider vertical></v-divider>
          <!-- Current Page control -->
          <!-- <v-btn
            @click="prevPage()"
            title="Previous Page"
            icon text>
            <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>-->

          <!-- <v-text-field style="width: 2em;" label="" v-model="page_id"></v-text-field>
                    aus {{ antragDruckTemplate.pages.length }}-->
          <!-- <span>{{ parseInt(page_id) + 1 }} / {{ antragDruckTemplate.pages.length }}</span> -->
          <v-row class="ma-1">
            <v-col>
              <v-select
                style="width: 5em"
                small
                dense
                v-model="page_id"
                :label="`Pages (${pagesList.length})`"
                :items="pagesList"
              ></v-select>
            </v-col>
          </v-row>

          <!-- <v-text-field label="" v-model="page_id"></v-text-field> -->

          <!-- <v-btn
            @click="nextPage()"
            title="Next Page"
            icon text>
            <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>-->

          <v-btn @click="addPage()" title="Add Page" text icon>
            <v-icon>mdi-note-plus</v-icon>
          </v-btn>

          <v-btn @click="deletePage()" title="Delete Page" text icon>
            <v-icon>mdi-delete-forever</v-icon>
          </v-btn>

          <v-divider vertical></v-divider>
          <v-row class="ma-1">
            <v-col>
              <v-text-field
                style="max-width: 5em"
                label="abs_id"
                v-model="abs_id"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn @click="initTemplate" title="Reload" icon text>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-divider vertical></v-divider>
          <!-- <v-btn text class="blue lighten-3">
            {{ selectedTarif.name }}
                    </v-btn>-->
          <v-row class="ma-1">
            <v-col>
              <v-select
                item-color="red"
                dense
                style="max-width: 400px"
                v-model="selectedTarif"
                :items="adtgTarife"
                item-text="name"
                label="Select Tarif"
                return-object
                single-line
              ></v-select>
            </v-col>
          </v-row>
          <v-btn
            icon
            text
            title="Antrag-Fragen. Dokumentation"
            @click="show_antrag_fragen = !show_antrag_fragen"
          >
            <v-icon>mdi-comment-question-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            text
            target="_blank"
            title="Tarife-Manager"
            to="/tarife-manager"
          >
            <v-icon>mdi-book-open-page-variant</v-icon>
          </v-btn>
          <!-- Pages: {{ antragDruckTemplate.pages.length }} -->
          <!-- END Current Page control -->

          <!-- draggedIndex {{ draggedIndex}} -->

          <!-- selectedFunction: {{ selectedFunction }} -->
          <!-- &nbsp;&nbsp;&nbsp; mouse: {{ mouseX }}px, {{ mouseY }}px &nbsp;&nbsp;&nbsp;  {{ Math.round(mouseX /5.9, 1)}}mm,  {{ Math.round(mouseY /5.9 + 2.5, 1)}}mm -->
        </v-toolbar-items>
      </v-toolbar>
      <v-container
        v-show="loadingPercent != 100"
        absolute
        style="
          position: fixed;
          top: 0;
          z-index: 10;
          background: white;
          opacity: 0.8;
          width: 100%;
        "
      >
        <v-row align="center" justify="center">
          <v-col cols="11">
            <v-progress-linear v-model="loadingPercent"></v-progress-linear>
          </v-col>
          <v-col cols="1">{{ loadingPercent }} &percnt;</v-col>
        </v-row>
      </v-container>
      <!-- {{ conditionLogic }} -->
      <!-- v-show="loadingPercent==100" -->
      <v-container>
        <v-row v-if="this.antragDruckLayoutImage.image">
          <v-col>
            <div
              class="pdf-container noselect"
              id="pdf-container"
              :style="{
                backgroundImage: `url('${this.antragDruckLayoutImage.image}')`,
                position: 'relative',
                'background-size': `${this.antragDruckLayoutImage.width}px ${this.antragDruckLayoutImage.height}px`,
                width: `${this.antragDruckLayoutImage.width}px`,
                height: `${this.antragDruckLayoutImage.height}px`,
              }"
              @mousemove="mouseMove($event)"
              @mouseup="layoutMouseUp"
              @mousedown="layoutMouseDown"
            >
              <div
                id="crop-rectangle"
                v-show="cropData.x2 !== null"
                v-bind:style="{
                  position: 'absolute',
                  border: '1px solid #ccc',
                  top: `${parseInt(cropData.y1)}px`,
                  left: `${parseInt(cropData.x1)}px`,
                  width: parseInt(cropData.x2) - parseInt(cropData.x1) + 'px',
                  height: parseInt(cropData.y2) - parseInt(cropData.y1) + 'px',
                }"
              >
                &nbsp;
              </div>
              <!-- All Elements -->
              <!-- @mousedown.stop="elementMousedown($event, index)" -->
              <!-- @click.stop="elementMouseup(index)" -->
              <div
                v-bind:style="{
                  position: 'absolute',
                  top:
                    element.type != 'Image'
                      ? `${
                          parseInt(element.y) -
                          parseInt(element.style['font-size'])
                        }px`
                      : `${parseInt(element.y)}px`,
                  left: `${element.x}px`,
                }"
                class="element"
                :class="{
                  blue: !styleElementSelected(index),
                  'element-selected': styleElementSelected(index),
                  'lighten-4': true,
                }"
                v-for="(element, index) in elements"
                :key="`all-elements-${index}`"
                :title="elementTitle(index)"
                @mousedown.stop="elementMousedown(index)"
                @mouseup.stop="elementMouseup(index)"
              >
                <!-- element Image -->
                <div v-if="element.type == 'Image' && images[`image-${index}`]">
                  <img
                    :width="`${element.width}px`"
                    :height="`${element.height}px`"
                    :src="images[`image-${index}`]"
                  />
                </div>
                <!-- element Variable -->
                <!-- :style="[elementStyles[index], elementStyle(index), conditionalStyle(index)]" -->
                <div
                  v-if="element.type == 'Variable'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <!-- <div v-if="antragData[element.variable_name][element.variable_field]" > -->
                  <div
                    v-if="
                      !isEmpty(element.variable_name, element.variable_field)
                    "
                  >
                    {{
                      antragData[element.variable_name][element.variable_field]
                    }}
                  </div>
                  <div v-else>empty</div>
                </div>

                <!-- element BAP -->
                <div
                  v-if="element.type == 'BAP'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span>{{ element.text }}</span>
                </div>
                <!-- element BAP -->
                <div
                  v-if="element.type == 'SignKunde'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span>{{ element.text }}</span>
                </div>
                <div
                  v-if="element.type == 'SignKundeVP'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span>{{ element.text }}</span>
                </div>
                <div
                  v-if="element.type == 'SignKundeOrt'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span>{{ element.text }}</span>
                </div>

                <div
                  v-if="element.type == 'SignKundeDatum'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span>{{ element.text }}</span>
                </div>

                <!-- element Text -->
                <div
                  v-if="element.type == 'Text'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span>{{ element.text }}</span>
                </div>
                <!-- element DruckLabel -->
                <div
                  v-if="element.type == 'DruckLabel'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span
                    >Original. Bitte zurücksenden an: Vermittler Lohstraße 56
                    81543 München - aid:
                    {{ antragData["abschluss"].abs_aid }}</span
                  >
                </div>
                <!-- element Kreuz -->
                <!-- :style="[elementStyles[index], elementStyle(index), conditionalStyle(index)]" -->
                <div
                  v-if="element.type == 'Kreuz'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span>x</span>
                </div>

                <div
                  v-if="element.type == 'VermittlerSign'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span>{{ element.text }}</span>
                </div>
                <div
                  v-if="element.type == 'Arrow'"
                  :style="[elementStyles[index], conditionalStyle(index)]"
                >
                  <span>&rarr;</span>
                </div>
              </div>
              <!-- end #pdf_container -->
            </div>
          </v-col>
          <v-col class="menu-right grey lighten-4">
            <div>
              aid: {{ aid }}
              abs_id:&nbsp;
              <a
                target="_blank"
                :href="`${flowUrl}gojoe/abschluss/${abs_id}`"
                >{{ abs_id }}</a
              >
              tarif_id: {{ selectedTarif.tarif_id }}
              <hr />
            </div>
            <div v-if="antragDruckTemplate.comment">
              <h3>Comment</h3>
              <div class="grey lighten-3 pa-3">
                {{ antragDruckTemplate.comment }}
              </div>
            </div>
            <!-- tarifStatus -->
            <tarife-manager
              :small="true"
              :tarif-id="parseInt(selectedTarif.tarif_id)"
            ></tarife-manager>
            <!-- printing settings -->
            <h3>Print</h3>
            <v-container fluid class="ma-0 pa-0">
              <v-row align="start" justify="start" dense>
                <v-col
                  sm="3"
                  v-for="(prop, key) in enabledPrintPageProps"
                  :key="key"
                >
                  <!-- @change="updateTemplateStatus($event, tarif, prop.position)" -->
                  <v-checkbox
                    v-model="pagePrintSettings[page_id][prop.position]"
                    @change="updatePagePrintSettings(page_id)"
                    :label="`${prop.text}`"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <!-- {{pagePrintSettings[page_id]}}
                                    {{antragDruckTemplate.pages[page_id].print_settings}}-->
                </v-col>
              </v-row>
            </v-container>
            <!-- <div class="druck_copie_only">
                    <v-switch
                        @change="template_saved =false"
                        v-model="antragDruckTemplate.pages[page_id].druck_copie_only"
                        :label="`${(antragDruckTemplate.pages[page_id].druck_copie_only)?'Nur Kopie drucken':'Original + Kopie drucken'}`"
                    ></v-switch>
                        </div>-->
            <h3>Hintegrundbild</h3>
            <div>
              <div
                v-if="template_load_complete"
                :class="layoutImageHasCorrectSize() ? '' : 'red lighten-5'"
              >
                <div class="crop_functions">
                  <v-select
                    small
                    :value="getLayoutImage()"
                    @change="updateLayoutImage($event)"
                    label="Layout image"
                    :items="antragImagesList"
                    menu-props="auto"
                  ></v-select>
                  {{ this.antragDruckLayoutImage.width }}x{{
                    this.antragDruckLayoutImage.height
                  }}
                  <!-- Crop buttons. visible only if layout image has correct size -->
                  <v-btn
                    icon
                    v-show="layoutImageHasCorrectSize()"
                    title="Crop Rectangle"
                    :elevation="selectedFunction == 'crop' ? 4 : 0"
                    @click="setFunction('crop')"
                  >
                    <v-icon>crop</v-icon>
                  </v-btn>
                  <v-btn
                    v-show="layoutImageHasCorrectSize() && cropData.x2 !== null"
                    title="modify"
                    @click="submitCrop()"
                    >modify</v-btn
                  >
                </div>
                <!-- end .crop_functions -->
                <div class="page_orientation">
                  <v-switch
                    @change="changeLayoutOrientation($event, page_id)"
                    :value="
                      antragDruckTemplate.pages[page_id]
                        .layout_landscape_orientation
                    "
                    :label="`${
                      antragDruckTemplate.pages[page_id]
                        .layout_landscape_orientation
                        ? 'Landscape'
                        : 'Portrait'
                    }`"
                  ></v-switch>
                </div>
                <!-- // end cropt buttons -->
              </div>
              <hr />
              <div v-if="antragData['kunde']">
                <h3>Kunde</h3>
                <div class="grey lighten-4">
                  <span class="grey--text text--darken-2">VN:</span>
                  {{ antragData["kunde"].kunde_fullname }}
                  <br />
                  <div class="grey--text text--darken-2 pl-10">
                    {{ antragData["kunde"].geburtstag }}
                    {{ antragData["kunde"].berufb }}
                    <!-- {{ antragData['kunde'].alter }} Jahre -->
                  </div>
                  <div v-show="antragData['kunde'].versicherungsnehmer != ''">
                    <span class="grey--text text--darken-2">VP:</span>
                    {{ antragData["kunde"].vp_fullname }}
                    <br />
                    <div class="grey--text text--darken-2 pl-10">
                      {{ antragData["kunde"].vgeburtstag }}
                      {{ antragData["kunde"].berufbv }}
                      <!-- {{ antragData['kunde'].alter_versicherte_person }} Jahre -->
                    </div>
                  </div>
                </div>
                <hr />
                <h3>Antragfragen</h3>
                <v-row v-for="n in tarifFragen.length" :key="n">
                  <v-col sm="3">
                    a{{ n }}:
                    <span class="black--text">{{
                      antragData["abschluss"][`abs_a${n}`]
                    }}</span>
                  </v-col>
                  <v-col>
                    <span class="caption">{{ tarifFrageText(n - 1) }}</span>
                  </v-col>
                </v-row>
              </div>
              <!--end kunde -->
            </div>
            <hr />
          </v-col>
        </v-row>
      </v-container>
      <div id="bottom">
        <a target="_blank" href="/tarife-manager">Tarife manager</a>
      </div>
    </div>
    <!-- APP_STARTED -->
  </div>
</template>

<script>
  // import DropdownList from '../DropdownList'

  import axios from "axios";
  import TarifeManager from "../TarifeManager";
  import BinaryHelper from "../../BinaryHelper";

  export default {
    name: "antrag-druck-template-generator",
    components: {
      // DropdownList
      TarifeManager,
    },
    data() {
      return {
        checktoken: false,
        absData: {
          abs_id: 0,
          token: "",
        },
        APP_STARTED: false,
        ergoTarife: [15, 70, 77, 78, 86, 133, 149, 185],
        ALL_GENERATED: false,
        GENERATING_ERGO_TEMPLATE: false,
        ergoResponse: null,
        PREVIEW_LINKS_VISIBLE: false,
        PDF_UPLOAD_VISIBLE: false,
        GENERATOR_STARTED: false,
        FILE_IS_UPLOADING: false,
        UPLOAD_DOCUMENT_TYPE: "antrag",
        HISTORY_MODE: false,
        printPageSettings: [],
        enabledPrintPageProps: [
          // { position: 0, text: "Prop 0" },
          // { position: 1, text: "Prop 1" },
          // { position: 2, text: "Prop 2" },
          // { position: 3, text: "Prop 3" },
          // { position: 4, text: "Prop 4" },
          // { position: 5, text: "Prop 5" },
          { position: 6, text: "Original" },
          { position: 7, text: "Kopie" },
        ],
        cropData: {
          x1: null,
          y1: null,
          x2: null,
          y2: null,
        },
        aid: null,
        abs_id: 253630,
        show_antrag_fragen: false,
        template_saved: true,
        elementVariableName: [],
        conditionLogic: [],
        page_id: 0,
        selectedTarif: null,
        // selectedTarif: {
        //   "name": 'Dummy',
        //   "tarif_id": 1,
        //   "template_status": 0
        // },
        selectedElement: null,
        elementToInsert: "Kreuz",
        selected_element_index: null,
        elementsList: [
          "SignKunde",
          "SignKundeVP",
          "VermittlerSign",
          "BAP",
          "Variable",
          "Text",
          "Kreuz",
          "Image",
          "Arrow",
          "DruckLabel",
        ],
        attachment: { name: null, file: null },
        imageUpdateData: {
          elements: [],
        },
        adtgTarife: [],
        colors: [
          { text: "default", value: "", rgb: [0, 0, 0] },
          { text: "red", value: "red", rgb: [255, 0, 0] },
          { text: "green", value: "green", rgb: [0, 255, 0] },
          { text: "blue", value: "blue", rgb: [0, 0, 255] },
        ],
        selectedFunction: "select",
        images_count: 0,
        draggedIndex: null,
        drawerAntragDruck: false,
        mouseX: 0,
        mouseY: 0,
        elementStyles: [],
        accesibleVariables: [
          "kunde",
          "bearbeiter",
          "tarif",
          "preis",
          "preisNeu",
          "versicherungsbeginn",
          "abschluss",
        ],
        selectedFunctions: "",
        conditionTypes: [
          "Empty",
          "NotEmpty",
          "EqualsValue",
          "NotEqualsValue",
          "GreaterThanValue",
          "LesserThanValue",
        ],
      };
    },
    mounted: function () {
      this.binaryHelper = new BinaryHelper();
      if (localStorage.page_id !== undefined) {
        this.page_id = parseInt(localStorage.page_id);
      }
      if (localStorage.abs_id) {
        this.abs_id = localStorage.abs_id;
      }
    },
    created() {
      this.initTemplate();
      // document.dispatchEvent(new Event("x-app-rendered"))
    },
    watch: {
      elementToInsert() {
        this.selectedFunction = "add";
      },
      selectedTarif: function () {
        this.$log.debug("watch selectedTarif", this.selectedTarif);
        // this.$log.debug('localStorage.tarif_id', localStorage.tarif_id)
        localStorage.tarif_id = this.selectedTarif.tarif_id;
        this.selectedElement = null;
        this.selected_element_index = null;
        if (this.template_load_complete) {
          this.page_id = 0;
        }
        this.fetchAllData();
      },
      abs_id: function (abs_id) {
        localStorage.abs_id = abs_id;
      },
      page_id: function (page_id) {
        this.$log.debug("watch page_id", page_id);
        localStorage.page_id = page_id;

        if (!this.selectedTarif) {
          return;
        }
        this.selectedElement = null;
        this.selected_element_index = null;
        this.$store
          .dispatch("antragdruck/getAntragDruckLayoutImage", {
            tarif_id: this.selectedTarif.tarif_id,
            image: this.getLayoutImage(),
          })
          .then(() => {
            this.loadTemplateImages();
            this.initElementStyles();
          });
      },
    },
    methods: {
      changeLayoutOrientation($event, page_id) {
        this.template_saved = false;
        let value = false;
        if ($event) {
          value = true;
        }
        this.$store
          .dispatch("antragdruck/changeLayoutOrientation", {
            page_id: page_id,
            value: value,
          })
          .then(() => {});
      },
      layoutImageHasCorrectSize() {
        if (!this.antragDruckLayoutImage) {
          // image not loaded
          return false;
        }
        let width_correct = 1240;
        let height_correct = 1754;
        if (
          this.antragDruckTemplate.pages[this.page_id]
            .layout_landscape_orientation
        ) {
          width_correct = 1754;
          height_correct = 1240;
        }
        if (
          this.antragDruckLayoutImage.width == width_correct &&
          this.antragDruckLayoutImage.height == height_correct
        ) {
          // correct size
          return true;
        }
        // incorect size
        return false;
      },
      addRectangle(x1, y1, x2, y2, strokeColor, fillColor, backgroundColor) {
        let rectangle = {
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2,
          strokeColor: strokeColor,
          fillColor: fillColor,
          backgroundColor: backgroundColor,
        };
        this.imageUpdateData.push(rectangle);
      },
      onFileChange(event) {
        this.attachment.file = event.target.files[0];
      },
      doFileUpload() {
        this.FILE_IS_UPLOADING = true;
        let formData = new FormData();
        formData.append("file", this.attachment.file);
        formData.set("UPLOAD_DOCUMENT_TYPE", this.UPLOAD_DOCUMENT_TYPE);
        axios
          .post(
            `${this.apiUrl}antragdruckapi/upload_pdf_file/${this.selectedTarif.tarif_id}`,
            formData,
            {
              headers: {
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
              },
            }
          )
          .then(() => {
            this.FILE_IS_UPLOADING = false;
            this.$log.debug("uploaded");
            this.initTemplate();
          });
      },
      selectAnbschluss(abschluss) {
        this.abs_id = abschluss.abs_id;
        this.initTemplate();
      },
      generateErgoTemplate() {
        if (!this.ergoTarife.includes(parseInt(this.selectedTarif.tarif_id))) {
          this.$log.error("not Ergo tarif");
          return;
        }
        this.GENERATING_ERGO_TEMPLATE = true;
        this.$store
          .dispatch("antragdruck/getErgoAntrag", {
            tarif_id: this.selectedTarif.tarif_id,
            abs_id: this.abs_id,
          })
          .then((resp) => {
            this.$log.error(resp);
            this.ergoResponse = resp.data;
            this.GENERATING_ERGO_TEMPLATE = false;
          });
        // .then(() => this.generateAntragMakeQuery())
      },
      generateAntragAndBeiblaetter() {
        this.$log.debug("generateAntrag");
        this.ALL_GENERATED = false;
        this.GENERATOR_STARTED = true;
        this.$store.dispatch("antragdruck/resetGenerated").then(() => {
          if (this.ergoTarife.includes(this.selectedTarif.tarif_id)) {
            this.$store
              .dispatch("antragdruck/getErgoAntrag", {
                tarif_id: this.selectedTarif.tarif_id,
                abs_id: this.abs_id,
              })
              .then(() => this.generateAntragMakeQuery());
          } else {
            this.generateAntragMakeQuery();
          }
        });
      },
      generateAntragMakeQuery() {
        let data = {
          tarif_id: this.selectedTarif.tarif_id,
          abs_id: this.abs_id,
        };
        Promise.all([
          this.$store.dispatch("antragdruck/generateFileAntrag", data),
          this.$store.dispatch(
            "antragdruck/generateFileAnschreiben",
            this.abs_id
          ),
          this.$store.dispatch(
            "antragdruck/generateFileClearment",
            this.abs_id
          ),
          this.$store.dispatch("antragdruck/generateFileHinweis", this.abs_id),
          this.$store.dispatch(
            "antragdruck/generateFileTarifbeschreibung",
            this.abs_id
          ),
        ])
          .then(() => {
            // mark as generated
            // this.$store.dispatch("antragdruck/markAntragAsGenerated", {abs_id: this.abs_id})
          })
          .catch((resp) => {
            // check all Pages generated
            this.$log.error(resp);
          })
          .finally(() => {
            let ALL_GENERATED = true;
            for (let key in this.generated) {
              if (this.generated[key].status != "OK") {
                ALL_GENERATED = false;
              }
            }
            this.ALL_GENERATED = ALL_GENERATED;
          });
      },
      restoreHistory(file) {
        this.$log.debug("history");
        this.$store
          .dispatch("antragdruck/restoreHistory", {
            file: file,
            tarif_id: this.selectedTarif.tarif_id,
          })
          .then(() => this.initTemplate());
      },
      abschlussTitle(abschluss) {
        // this.$log.debug('abschlussTitle: ', abschluss)
        // vn geburtstag, alter
        let strings = [];
        let [day, month, year] = abschluss.geburtstag.split("-");
        let date = new Date(year, parseInt(month) - 1, day);
        let alter = this.calculateAge(date);
        let s = `vn ${abschluss.geburtstag} Alter: ${alter} `;
        strings.push(s);
        // vp geburtstag, alter
        let [vp_day, vp_month, vp_year] = abschluss.vgeburtstag.split("-");
        let vp_date = new Date(vp_year, parseInt(vp_month) - 1, vp_day);
        let vp_alter = this.calculateAge(vp_date);
        s = `vp ${abschluss.vgeburtstag} Alter: ${vp_alter} `;
        strings.push(s);
        let val = "";
        for (let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
          val = '""';
          if (abschluss["abs_a" + i]) {
            val = abschluss["abs_a" + i];
          }
          s = `a${i}: ${val}`;
          strings.push(s);
        }
        let title = strings.join("\n");
        return title;
      },
      // birthday is a date
      calculateAge(birthday) {
        var ageDifMs = Date.now() - birthday;
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      },
      isEmpty(variable_name, variable_field) {
        if (
          this.antragData[variable_name][variable_field] === undefined ||
          this.antragData[variable_name][variable_field] === null ||
          this.antragData[variable_name][variable_field] === ""
        ) {
          return true;
        }
        return false;
      },
      prevPage() {
        if (this.page_id != 0) {
          this.page_id = parseInt(this.page_id) - 1;
        }
      },
      nextPage() {
        this.$log.debug(this.page_id);
        this.$log.debug(this.antragDruckTemplate.pages.length);
        if (this.page_id + 1 < this.antragDruckTemplate.pages.length) {
          this.page_id = parseInt(this.page_id) + 1;
        }
      },
      updateComment(comment) {
        this.template_saved = false;
        this.$log.debug(comment);
        this.$store.dispatch("antragdruck/updateComment", comment);
      },
      updateDefaultFontSize(size) {
        this.template_saved = false;
        this.$store
          .dispatch("antragdruck/updateDefaultFontSize", {
            page_id: this.page_id,
            default_font_size: size,
          })
          .then(() => {
            for (let index in this.elements) {
              if (this.elements[index].type == "Variable")
                this.updateElementStyle(size, index, "font-size");
            }
          })
          .finally(() => {
            this.initElementStyles();
            this.$forceUpdate();
          });
      },
      updateLayoutImage(image) {
        this.$store
          .dispatch("antragdruck/updateLayoutImage", {
            page_id: this.page_id,
            image: image,
          })
          .then(() => {
            this.saveAntragDruckTemplate().then(() => this.fetchAllData());
          });
      },
      addPage() {
        this.$store
          .dispatch("antragdruck/addPage")
          .then(() => this.$forceUpdate());
      },
      deletePage() {
        if (window.confirm("Really delete?")) {
          let delete_page_id = this.page_id;
          this.page_id = 0;
          this.$store
            .dispatch("antragdruck/deletePage", delete_page_id)
            .then(() =>
              this.saveAntragDruckTemplate().then(() => this.initTemplate())
            );
        }
      },
      updateElementStyle($event, index, field) {
        this.template_saved = false;
        this.$log.debug($event, index, field);
        let element = Object.assign({}, this.elements[index]);
        element.style[field] = $event;
        if (field == "font-size") {
          this.elementStyles[index][field] = `${$event}px`;
        } else {
          this.elementStyles[index][field] = $event;
        }
        if (field == "font-weight") {
          if ($event) {
            this.elementStyles[index][field] = "bold";
          } else {
            this.elementStyles[index][field] = "";
          }
          this.$log.debug("font-weight", $event);
        }
        this.$log.debug("update element", element);
        // check is there a better method as $forceUpdate()
        this.$store
          .dispatch("antragdruck/updateElement", {
            page_id: this.page_id,
            index: index,
            element: element,
          })
          .then(() => {
            this.$forceUpdate();
          });
      },
      updateElement($event, index, field) {
        this.template_saved = false;
        this.$log.debug($event, index, field);
        let element = Object.assign({}, this.elements[index]);
        element[field] = $event;
        // check is there a better method as $forceUpdate()
        this.$store
          .dispatch("antragdruck/updateElement", {
            page_id: this.page_id,
            index: index,
            element: element,
          })
          .then(() => this.$forceUpdate());
      },
      styleElementSelected(index) {
        /* let element = this.elements[index] */
        if (index == this.selected_element_index) {
          return true;
        } else {
          return false;
        }
      },
      addCondition(index) {
        this.template_saved = false;
        this.$log.debug("addCondition", index);
        this.$log.debug("element", this.elements[index]);
        let element = Object.assign({}, this.elements[index]);
        let condition = {
          type: "Empty",
          variable_name: "kunde",
          variable_field: "versicherungsnehmer",
        };
        element.conditions.push(condition);
        this.$store
          .dispatch("antragdruck/updateElement", {
            page_id: this.page_id,
            index: index,
            element: element,
          })
          .then(() => this.$forceUpdate());
      },
      initTemplate() {
        this.$log.debug("iniTemplate start");
        this.$store
          .dispatch("antragdruck/getAdtgTarife")
          .then((resp) => {
            // this.$log.debug("got response from getAdtgTarife", resp);
            // this.$log.debug(
            //     "got response from getAdtgTarife",
            //     Object.keys(resp)
            // );
            // this.$log.debug(typeof resp);
            if (resp.status == 200) {
              this.APP_STARTED = true;
            }
            this.adtgTarife = resp.data;
            if (localStorage.tarif_id !== undefined) {
              this.$log.debug(localStorage.tarif_id);
              let selTarif = this.adtgTarife.filter(
                (tarif) => tarif.tarif_id == localStorage.tarif_id
              );
              this.$log.debug("setting selectedTarif", selTarif);
              if (selTarif[0]) {
                this.selectedTarif = selTarif[0];
              }
              this.$log.debug("selectedTarif: ", this.selectedTarif);
            }
            this.fetchAllData();
          })
          .catch(
            //error
            (resp) => {
              this.$log.error("error", resp);
            }
          );
      },
      antragapiRequest() {
        this.$store
          .dispatch(
            "antragdruck/getAntragDruckTemplate",
            this.selectedTarif.tarif_id
          )
          .then(() => {
            this.$log.debug("check getLayoutImage()", this.getLayoutImage());
            if (this.getLayoutImage() != "") {
              let data = {
                tarif_id: this.selectedTarif.tarif_id,
                image: this.getLayoutImage(),
              };

              this.$store
                .dispatch("antragdruck/getAntragDruckLayoutImage", data)
                .then(() => this.loadTemplateImages());
            }
          });

        Promise.all([
          this.$store.dispatch(
            "antragdruck/getTarif",
            this.selectedTarif.tarif_id
          ),
          this.$store.dispatch(
            "antragdruck/getTarifFragen",
            this.selectedTarif.tarif_id
          ),
          //this.$store.dispatch("antragdruck/getAbschluss", this.absData),
          this.$store.dispatch("antragdruck/getAbschlussIntern", this.abs_id),
        ])
          .then(() => {
            // this.$log.debug('test', this.antragData.abschluss.abs_antrag_generated)
            // if (this.antragData.abschluss.abs_antrag_generated != '0000-00-00 00:00:00') {
            //   this.ALL_GENERATED = true
            // }
            this.aid = this.antragData.abschluss.abs_aid;
            this.$store.dispatch(
              "antragdruck/getAntragImagesList",
              this.selectedTarif.tarif_id
            );
            this.$store.dispatch(
              "antragdruck/getLastAbschlussFromTarifIntern",
              this.selectedTarif.tarif_id
            );
            this.$store
              .dispatch("antragdruck/getKundeIntern", {
                aid: this.aid,
                tarif_id: this.selectedTarif.tarif_id,
              })
              .then(() => {
                this.$store.dispatch(
                  "antragdruck/getBearbeiterIntern",
                  this.antragData.kunde.flow_bearbeiter
                );

                // let versicherungsbeginnData = {
                //   tarif_methode_alter:
                //     this.antragData.tarif.tarif_int_methode_alter,
                //   verlegenMonate: this.antragData.abschluss.abs_beginn_plus,
                //   date_antrag_generated:
                //     this.antragData.abschluss.abs_antrag_generated,
                // };

                this.handleEintritsalter();
                // this.$store
                //   .dispatch(
                //     "antragdruck/getVersicherungsbeginn",
                //     versicherungsbeginnData
                //   )
                //   .then(this.handleVersicherungsbeginn);
              });
            this.$store.dispatch(
              "antragdruck/getHistoryList",
              this.selectedTarif.tarif_id
            );
            // this.$store.dispatch("antragdruck/getPreis", {abs_ber_id: this.antragData.abschluss.abs_ber_id, tarif_id: this.selectedTarif.tarif_id, abs_beginn_plus: 0})
          })
          .finally(() => {
            this.initElementStyles();
            /* this.$log.debug('antragData', this.antragData) */
            /* this.$log.debug('template loaded') */
          });
      },
      fetchAllData() {
        if (!this.selectedTarif.tarif_id) {
          return;
        }
        this.$log.debug("resetTemplate");
        this.$store
          .dispatch("antragdruck/resetTemplate")
          .then(this.antragapiRequest);
      },
      handlePreis(resp) {
        // this.$log.debug(" response dispatch/getPreisEintrittsalter", resp);
        return resp;
      },
      // handleVersicherungsbeginn(resp) {
      //   this.$log.debug(this.antragData.kunde.geburtstag);
      //   let geburtsdatum = this.antragData.kunde.geburtstag;
      //   if (this.antragData.kunde.vgeburtstag) {
      //     geburtsdatum = this.antragData.kunde.vgeburtstag;
      //   }
      //   let data = {
      //     geburtsdatum: geburtsdatum,
      //     versicherungsbeginn: resp.data.versicherungsbeginn,
      //   };
      //   this.$store
      //     .dispatch("antragdruck/getEintrittsalter", data)
      //     .then(this.handleEintritsalter);
      // },
      handleEintritsalter() {
        let getPreisData = {
          tarif_id: this.selectedTarif.tarif_id,
          ber_id: this.antragData.abschluss.abs_ber_id,
          plus_monat: this.antragData.abschluss.abs_beginn_plus,
        };
        this.$log.debug("dispatch/getPreisEintrittsalter");
        this.$store.dispatch(
          "antragdruck/getPreisEintrittsalter",
          getPreisData
        );
      },
      loadTemplateImages() {
        this.images_count = 0;
        // count images
        for (let element of this.elements) {
          if (element.type == "Image") {
            /* this.$log.debug('found image') */
            this.images_count = this.images_count + 1;
          }
        }
        // fetch images
        for (let element_index in this.elements) {
          let element = this.elements[element_index];
          if (element.type == "Image") {
            /* this.$log.debug('get Image') */
            this.$store.dispatch("antragdruck/getImage", {
              image: element.image,
              element_index: element_index,
            });
            // .then(() => this.$log.debug('images', this.images))
          }
        }
      },
      computeCondition(condition) {
        // this.$log.debug('condition', condition)
        let variable_name = condition.variable_name;
        let variable_field = condition.variable_field;
        let val = this.antragData[variable_name][variable_field];
        // this.$log.debug('var', variable_name)
        // this.$log.debug('field', variable_field)
        // this.$log.debug('check val', val)

        if (condition.type == "NotEqualsValue") {
          // mark grey hidden elements
          if (
            condition.compare_value === null ||
            condition.compare_value === undefined
          ) {
            return false;
          }
          if (this.isNormalInteger(condition.compare_value)) {
            // val is integer
            if (!val || parseInt(val) === parseInt(condition.compare_value)) {
              return false;
            } else {
              return true;
            }
          } else {
            // val is string
            if (!val || val.localeCompare(condition.compare_value === 0)) {
              return false;
            } else {
              return true;
            }
          }
        }
        if (condition.type == "EqualsValue") {
          // mark grey hidden elements
          if (
            condition.compare_value === null ||
            condition.compare_value === undefined
          ) {
            return false;
          }
          if (this.isNormalInteger(condition.compare_value)) {
            // val is integer
            if (!val || parseInt(val) != parseInt(condition.compare_value)) {
              return false;
            } else {
              return true;
            }
          } else {
            // val is string
            if (!val || val.localeCompare(condition.compare_value !== 0)) {
              return false;
            } else {
              return true;
            }
          }
        }
        if (condition.type == "GreaterThanValue") {
          // mark grey hidden elements
          if (!condition.compare_value) {
            return false;
          }
          if (!val || parseInt(val) <= parseInt(condition.compare_value)) {
            return false;
          } else {
            return true;
          }
        }
        if (condition.type == "LesserThanValue") {
          // mark grey hidden elements
          // this.$log.debug('lesserThanValue', val)
          // this.$log.debug('condition.compare_value', condition.compare_value)
          if (!condition.compare_value) {
            return false;
          }
          if (!val || parseInt(val) >= parseInt(condition.compare_value)) {
            return false;
          } else {
            return true;
          }
        }
        if (condition.type == "Empty") {
          // mark grey hidden elements
          if (val && val != "") {
            return false;
          } else {
            return true;
          }
        }
        if (condition.type == "NotEmpty") {
          // mark grey hidden elements
          if (!val || val == "") {
            return false;
          } else {
            return true;
          }
        }
        return true;
      },
      isNormalInteger(str) {
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
      },
      conditionalStyle(index) {
        if (!this.template_load_complete) {
          return {};
        }
        // this.$log.debug(index)
        let style = {};
        let element = Object.assign({}, this.elements[index]);
        this.conditionLogic[index] = [];
        if (element.conditions.length) {
          for (let condition of element.conditions) {
            let computedCondition = this.computeCondition(condition);
            this.conditionLogic[index].push(computedCondition);
          } // end for
        } // end if conditions.length
        let showElement = true;
        for (let conditionBoolean of this.conditionLogic[index]) {
          showElement = showElement && conditionBoolean;
        }
        if (element.type == "Kreuz" || element.type == "Arrow") {
          if (
            this.antragData[element.variable_name][element.variable_field] != 1
          ) {
            showElement = false;
          }
        }
        // this.$log.debug('showElement', showElement)
        if (!showElement) {
          style = {
            "background-color": "rgba(250, 250, 250, 0.8)",
            color: "grey",
          };
        }
        return style;
      },
      saveAntragDruckTemplate() {
        let data = {
          tarif_id: this.selectedTarif.tarif_id,
          template: this.antragDruckTemplate,
        };
        return this.$store
          .dispatch("antragdruck/saveAntragDruckTemplate", data)
          .then(() => {
            this.template_saved = true;
            return this.$store.dispatch(
              "antragdruck/getHistoryList",
              this.selectedTarif.tarif_id
            );
          });
      },
      initElementStyles() {
        this.elementStyles = [];
        for (let index in this.elements) {
          if (this.elements[index].style === undefined) {
            this.elements[index].style = {};
          }
          if (!this.elements[index].style["font-size"]) {
            let element = Object.assign({}, this.elements[index]);
            element.style["font-size"] = `${this.default_font_size}`;
            this.$store
              .dispatch("antragdruck/updateElement", {
                page_id: this.page_id,
                index: index,
                element: element,
              })
              .then(() => {
                this.$forceUpdate();
              });
          }
          this.elementStyles[index] = Object.assign(
            {},
            this.elements[index].style
          );
          this.elementStyles[index][
            "font-size"
          ] = `${this.elements[index].style["font-size"]}px`;
          // this.$log.debug('elements', index, this.elements[index].style)
          // this.$log.debug('elementStyles', index, this.elementStyles[index])

          //   let element = Object.assign({}, this.elements[index])
          //   if (!element.style['font-size'] || element.style['font-size'] == '') {

          //   }
          //   // this.$store.dispatch('antragdruck/updateElement', {page_id: this.page_id, index: index, element: element})
          // }
        }
      },
      mouseMove($event) {
        // this.$log.debug('mouse move', $event)
        this.mousePosition($event);
        if (this.draggedIndex !== null) {
          // this.$log.debug('mouse move draggedIndex !== null')
          let element = Object.assign({}, this.elements[this.draggedIndex]);
          element.x = this.mouseX;
          element.y = this.mouseY;
          this.$store
            .dispatch("antragdruck/updateElement", {
              page_id: this.page_id,
              index: this.draggedIndex,
              element: element,
            })
            .then(() => {
              this.template_saved = false;
              this.$forceUpdate();
            });
        }
        if (this.selectedFunction == "crop" && this.cropData.x1 !== null) {
          this.cropData.x2 = this.mouseX;
          this.cropData.y2 = this.mouseY;
        }
      },
      elementMousedown(index) {
        if (this.selectedFunction != "move") {
          return;
        }
        // this.$log.debug($event)
        // this.elementStyles.drag = true;
        this.draggedIndex = index;
        // this.$log.debug('mousedown', index)
      },
      layoutMouseDown($event) {
        this.$log.debug($event);
        if (this.selectedFunction == "crop") {
          this.cropData.x1 = this.mouseX;
          this.cropData.y1 = this.mouseY;
        }
      },
      layoutMouseUp() {
        // this.$log.debug('layoutMouseUp')
        this.draggedIndex = null;
        if (this.selectedFunction == "add") {
          /* this.$log.debug('add') */
          // this.newVariableElement()
          if (this.elementToInsert == "BAP") {
            this.newBapElement();
          }
          if (this.elementToInsert == "VermittlerSign") {
            this.newVermittlerSignElement();
          }

          if (this.elementToInsert == "SignKunde") {
            this.newSignKundeElement();
          }
          if (this.elementToInsert == "SignKundeVP") {
            this.newSignKundeElement16();
          }
          if (this.elementToInsert == "SignKundeOrt") {
            this.newSignKundeOrtElement();
          }
          if (this.elementToInsert == "SignKundeDatum") {
            this.newSignKundeDatumElement();
          }
          if (this.elementToInsert == "Kreuz") {
            this.newKreuzElement();
          }
          if (this.elementToInsert == "Variable") {
            this.newVariableElement();
          }
          if (this.elementToInsert == "Arrow") {
            this.newArrowElement();
          }
          if (this.elementToInsert == "Image") {
            this.newImageElement();
          }
          if (this.elementToInsert == "Text") {
            this.newTextElement();
          }
          if (this.elementToInsert == "DruckLabel") {
            this.newDruckLabel();
          }
          this.selectedFunction = "select";
        } // end add
        if (this.selectedFunction == "crop") {
          this.cropData.x2 = this.mouseX;
          this.cropData.y2 = this.mouseY;
          this.selectedFunction = "select";
        }
      },
      elementMouseup(index) {
        // this.$log.debug('elementMouseup', index)
        if (!index && index != 0) {
          this.draggedIndex = null;
          return;
        }
        // this.$log.debug('selectedFunction: select', this.selectedFunction)
        if (this.selectedFunction == "select") {
          // this.$log.debug('selectedFunction: select', this.selectedFunction)

          this.selectedElement = this.elements[index];
          this.selected_element_index = index;
          setTimeout(() => (this.drawerAntragDruck = true), 1000);

          // scroll to element
          let container = document.getElementById("navigation-drawer");
          let el = document.getElementById(`element_${index}`);
          container.children[0].scrollTop = el.offsetTop;
        }
        this.draggedIndex = null;
        if (this.draggedIndex === null) return;
        /* this.$log.debug($event) */
        /* this.$log.debug(index) */
        // this.elementStyles.drag = false;
        // this.$log.debug('mouseup', index)
        // let pos = this.mousePosition($event)
        // this.elements[index].x = pos.x
        // this.elements[index].y = pos.y
      },
      handleControlElementMouseover(index) {
        this.elementStyles[index]["min-width"] = "10px";
        this.elementStyles[index]["min-height"] = "1em";
        this.elementStyles[index].border = "1px solid yellow";
        this.elementStyles[index].color = "red";
        this.elementStyles[index]["font-weight"] = "bold";
        this.elementStyles[index]["background"] = "black";
      },
      handleControlElementMouseout(index) {
        this.elementStyles[index].border = "none";
        this.elementStyles[index].color = "green";
        this.elementStyles[index]["font-weight"] = "normal";
        this.elementStyles[index]["background"] = "transparent";
      },
      deleteCondition(index, condition_index) {
        this.$log.debug("deleteCondtion", index, condition_index);
        this.elements[index].conditions.splice(condition_index, 1);
        this.initElementStyles();
      },
      deleteElement(index) {
        this.template_saved = false;
        this.elements.splice(index, 1);
        this.initElementStyles();
      },
      setFunction(func) {
        if (this.selectedFunction == func) {
          this.selectedFunction = "select";
        } else {
          this.selectedFunction = func;
        }
        if (func == "crop") {
          this.cropData.x1 = null;
          this.cropData.y1 = null;
          this.cropData.x2 = null;
          this.cropData.y2 = null;
        }
      },
      resetCropData() {
        this.cropData.x1 = null;
        this.cropData.y1 = null;
        this.cropData.x2 = null;
        this.cropData.y2 = null;
      },
      submitCrop() {
        this.cropData.file = `pdf_antrag/${
          this.selectedTarif.tarif_id
        }/${this.getLayoutImage()}`;
        this.$store
          .dispatch("antragdruck/cropImage", this.cropData)
          .then((resp) => {
            this.$log.debug("image cropped", resp);
            this.resetCropData();
            this.initTemplate();
          });
        this.selectedFunction = "select";
      },
      moveElement() {
        this.selectedFunction = "move";
      },
      addVariableElement() {
        this.newVariableElement();
      },
      addElement(element) {
        this.template_saved = false;
        this.elements.push(element);
      },
      newImageElement() {
        this.template_saved = false;
        let element = {
          type: "Image",
          variable_field: "aid",
          variable_name: "kunde",
          image: "unterschrift.png",
          width: 210,
          height: 40,
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.loadTemplateImages();
          });
        // this.addElement(element)
        // return element
      },
      newSignKundeOrtElement() {
        this.template_saved = false;
        let element = {
          type: "SignKunde",
          textLabel: "Unterschrift Ort",
          text: "Unterschrift Ort",
          variable_field: "aid",
          variable_name: "kunde",
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.initElementStyles();
          });
      },
      newSignKundeDatumElement() {
        this.template_saved = false;
        let element = {
          type: "SignKunde",
          textLabel: "Unterschrift Datum",
          text: "Unterschrift Datum",
          variable_field: "aid",
          variable_name: "kunde",
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.initElementStyles();
          });
      },

      newSignKundeElement16() {
        this.template_saved = false;
        let element = {
          type: "Image",
          imagetype: "KundenUnterschrift16",
          variable_field: "aid",
          variable_name: "kunde",
          image: "unterschriftKunde16.png",
          width: 210,
          height: 40,
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.loadTemplateImages();
          });
        // this.addElement(element)
        // return element
      },
      newSignKundeElement() {
        this.template_saved = false;
        let element = {
          type: "Image",
          imagetype: "KundenUnterschrift",
          variable_field: "aid",
          variable_name: "kunde",
          image: "unterschriftKunde.png",
          width: 210,
          height: 40,
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.loadTemplateImages();
          });
        // this.addElement(element)
        // return element
      },

      newVermittlerSignElement() {
        this.template_saved = false;
        let element = {
          type: "Image",
          imagetype: "VermittlerUnterschrift",
          variable_field: "aid",
          variable_name: "kunde",
          image: "unterschrift.png",
          width: 210,
          height: 40,
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.loadTemplateImages();
          });
        // this.addElement(element)
        // return element
      },

      newVermittlerSignElementBak() {
        this.$log.debug("savedVmSign");
        this.template_saved = false;
        let element = {
          type: "VermittlerSign",
          textLabel: "VermittlerUnterschrift",
          text: "VermittlerUnterschrift",
          variable_field: "aid",
          variable_name: "kunde",
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.initElementStyles();
          });
        // this.addElement(element)
        // return element
      },
      newBapElement() {
        this.template_saved = false;
        let element = {
          type: "BAP",
          textLabel: "Achtung: der monatliche Beitrag erhöht sich",
          text: "BAP Hinweis",
          variable_field: "aid",
          variable_name: "kunde",
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.initElementStyles();
          });
        // this.addElement(element)
        // return element
      },
      newTextElement() {
        this.template_saved = false;
        let element = {
          type: "Text",
          textLabel: "sample Label",
          text: "Sample Text",
          variable_field: "aid",
          variable_name: "kunde",
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.initElementStyles();
          });
        // this.addElement(element)
        // return element
      },
      newDruckLabel() {
        this.template_saved = false;
        let element = {
          type: "DruckLabel",
          text: `[abs_id: ${this.antragData["abschluss"].abs_id}, aid: ${this.antragData["abschluss"].abs_aid}]`,
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.initElementStyles();
          });
        // this.addElement(element)
        // return element
      },
      newArrowElement() {
        this.template_saved = false;
        let element = {
          type: "Arrow",
          variable_field: "kreuz_true",
          variable_name: "kunde",
          style: {
            "font-size": 50,
            color: "red",
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.initElementStyles();
          });
        // this.addElement(element)
        // return element
      },
      newKreuzElement() {
        this.template_saved = false;
        let element = {
          type: "Kreuz",
          variable_field: "kreuz_true",
          variable_name: "kunde",
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.initElementStyles();
          });
        // this.addElement(element)
        // return element
      },
      newVariableElement() {
        this.template_saved = false;
        let element = {
          type: "Variable",
          variable_field: "aid",
          variable_name: "kunde",
          style: {
            "font-size": `${this.default_font_size}`,
          },
          x: this.mouseX,
          y: this.mouseY,
          conditions: [],
        };
        this.$store
          .dispatch("antragdruck/addElement", {
            page_id: this.page_id,
            element: element,
          })
          .then(() => {
            this.initElementStyles();
          });
        // this.addElement(element)
        // return element
      },
      // handleClick(e) {
      // let pos = this.mousePosition(e)
      // },
      // get Mouse Position relative to image layout.
      mousePosition(e) {
        const container = document.getElementById("pdf-container");
        let container_position = this.getPosition(container);
        this.mouseX = e.clientX - container_position.x;
        this.mouseY = e.clientY - container_position.y;
        return {
          x: this.mouseX,
          y: this.mouseY,
        };
      },
      // get element position on screen
      getPosition(el) {
        let xPosition = 0;
        let yPosition = 0;
        while (el) {
          if (el.tagName == "BODY") {
            // deal with browser quirks with body/window/document and page scroll
            let xScrollPos =
              el.scrollLeft || document.documentElement.scrollLeft;
            let yScrollPos = el.scrollTop || document.documentElement.scrollTop;
            xPosition += el.offsetLeft - xScrollPos + el.clientLeft;
            yPosition += el.offsetTop - yScrollPos + el.clientTop;
          } else {
            xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
            yPosition += el.offsetTop - el.scrollTop + el.clientTop;
          }
          el = el.offsetParent;
        }
        return {
          x: xPosition,
          y: yPosition,
        };
      },
      getLayoutImage() {
        if (
          !this.antragDruckTemplate ||
          !this.antragDruckTemplate.pages.length ||
          !this.antragDruckTemplate.pages[this.page_id] ||
          !this.antragDruckTemplate.pages[this.page_id].layout_image
        ) {
          return "";
        }
        return this.antragDruckTemplate.pages[this.page_id].layout_image;
      },
      tarifFrageText(num) {
        let text = "";
        if (this.tarifFragen[num] && this.tarifFragen[num].antrag_text) {
          text = this.tarifFragen[num].antrag_text;
        }
        return text;
      },
      updatePagePrintSettings(page_id) {
        this.template_saved = false;
        this.$log.debug(this.pagePrintSettings[page_id]);
        let print_settings = this.binaryHelper.booleanArrayToInteger(
          this.pagePrintSettings[page_id]
        );
        this.$store.dispatch("antragdruck/updatePagePrintSettings", {
          page_id: this.page_id,
          print_settings: print_settings,
        });
        // .then(()=>{})
      },
      elementTitle(index) {
        // return ''
        let title = "";
        let element = this.elements[index];

        if (element.type == "Variable" || element.type == "Kreuz") {
          title = `${element.variable_name}.${element.variable_field}: `;
        }

        if (element.type == "Text") {
          title = `Label: ${element.textLabel}`;
        }

        if (element.type == "BAP") {
          title = `Label: ${element.textLabel}`;
        }
        if (element.type == "VermittlerSign") {
          title = `Label: ${element.textLabel}`;
        }

        if (element.type == "SignKunde") {
          title = `Label: ${element.textLabel}`;
        }
        if (element.type == "SignKundeVP") {
          title = `Label: ${element.textLabel}`;
        }
        if (element.type == "SignKundeOrt") {
          title = `Label: ${element.textLabel}`;
        }

        if (element.type == "SignKundeDatum") {
          title = `Label: ${element.textLabel}`;
        }

        if (
          element.type != "Text" &&
          element.type != "DruckLabel" &&
          element.type != "BAP" &&
          element.type != "VermittlerSign" &&
          element.type != "SignKunde" &&
          element.type != "SignKundeVP" &&
          element.type != "SignKundeOrt" &&
          element.type != "SignKundeDatum"
        ) {
          title +=
            this.antragData[element.variable_name][element.variable_field];
        }
        // Display Conditions
        if (element.conditions.length) {
          title += "\n Conditions (" + element.conditions.length + "):";
          for (let condition of element.conditions) {
            if (condition.type == "LesserThanValue") {
              title +=
                "\n " +
                condition.variable_name +
                "." +
                condition.variable_field +
                " < " +
                condition.compare_value;
            } else if (condition.type == "GreaterThanValue") {
              title +=
                "\n " +
                condition.variable_name +
                "." +
                condition.variable_field +
                " > " +
                condition.compare_value;
            } else if (condition.type == "EqualsValue") {
              title +=
                "\n " +
                condition.variable_name +
                "." +
                condition.variable_field +
                " == " +
                condition.compare_value;
            } else if (condition.type == "NotEqualsValue") {
              title +=
                "\n " +
                condition.variable_name +
                "." +
                condition.variable_field +
                " != " +
                condition.compare_value;
            } else {
              title +=
                "\n " +
                condition.type +
                " " +
                condition.variable_name +
                "." +
                condition.variable_field;
            }
          }
        }
        return title;
      },
    }, // end methods
    computed: {
      pagePrintSettings() {
        let status = [];
        this.antragDruckTemplate.pages.forEach((page) => {
          // default print_settings
          let print_settings = 1;
          if (page.print_settings) {
            print_settings = page.print_settings;
          }
          status.push(this.binaryHelper.integerToBooleanArray(print_settings));
        });
        // this.$log.debug('status', status);
        return status;
      },
      getLocalStorage() {
        return localStorage;
      },
      pagesList() {
        if (!this.antragDruckTemplate.pages) {
          return [];
        } else {
          return this.antragDruckTemplate.pages.map((el, key) => {
            return { text: parseInt(key) + 1, value: key };
          });
        }
      },
      // tarifList() {
      //     return this.$store.getters['antragdruck/adtgTarife']
      // },
      lastAbschluss() {
        return this.$store.getters["antragdruck/lastAbschluss"];
      },
      default_font_size() {
        if (
          !this.antragDruckTemplate ||
          !this.antragDruckTemplate.pages ||
          !this.antragDruckTemplate.pages.length ||
          !this.antragDruckTemplate.pages[this.page_id] ||
          !this.antragDruckTemplate.pages[this.page_id].default_font_size
        ) {
          return 16;
        } else {
          return this.antragDruckTemplate.pages[this.page_id].default_font_size;
        }
      },
      loadingPercent() {
        let val = this.$store.getters["antragdruck/loading_status"];
        if (val == 0) {
          return 100;
        }
        val = (10 / val) * 10;
        // val = Math.round(val * 100) / 100
        val = Math.round(val);
        return val;
      },
      template_load_complete() {
        // this.$log.debug("kunde", this.antragData.kunde);
        // this.$log.debug("preis", this.antragData.preis);
        if (
          this.antragData.kunde !== null &&
          this.antragData.bearbeiter !== null &&
          this.antragData.tarif !== null &&
          this.antragData.eintrittsalter !== null &&
          this.antragData.preis !== null &&
          this.antragData.versicherungsbeginn !== null
          //  && this.antragData.abschluss !== null &&
          // this.antragDruckLayoutImage !== null
          // this.adtgTarife.length
        ) {
          return true;
        } else {
          return false;
        }
      },
      // guest() {
      //   return this.$store.getters['auth/guest']
      // },
      antragData() {
        return this.$store.getters["antragdruck/antragData"];
      },
      antragDruckTemplate() {
        let antragDruckTemplate =
          this.$store.getters["antragdruck/antragDruckTemplate"];
        antragDruckTemplate.pages = antragDruckTemplate.pages.map((page) => {
          if (page.druck_copie_only === undefined) {
            page.druck_copie_only = true;
          }
          if (page.layout_landscape_orientation === undefined) {
            page.layout_landscape_orientation = false;
          }
          return page;
        });
        return antragDruckTemplate;
      },
      antragImagesList() {
        return this.$store.getters["antragdruck/antragImagesList"];
      },
      historyList() {
        return this.$store.getters["antragdruck/historyList"];
      },
      historyElements() {
        let elements = [];
        let history = this.historyList;
        if (!history) {
          return [];
        }
        history.forEach((el) => {
          let file = el;
          el = el.substring(0, el.length - 13);
          let splited = el.split("_");
          let date = splited[0];
          let time = splited[1];

          let element = {
            name: date,
            date: date,
            time: time,
            file: file,
          };
          elements.push(element);
        });
        return elements;
      },
      generated() {
        return this.$store.getters["antragdruck/generated"];
      },
      eintrittsalter() {
        return this.$store.getters["antragdruck/eintrittsalter"];
      },
      antragDruckLayoutImage() {
        return this.$store.getters["antragdruck/antragDruckLayoutImage"];
      },
      errors() {
        return this.$store.getters["antragdruck/errors"];
      },
      images() {
        return this.$store.getters["antragdruck/images"];
      },
      variableKeys() {
        return this.$store.getters["antragdruck/variableKeys"];
      },
      tarifFragen() {
        return this.$store.getters["antragdruck/tarifFragen"];
      },
      // tarif_id() {
      //   return this.selectedTarif.tarif_id
      // },
      backgroundImage() {
        let layout_image = this.getLayoutImage();
        //   this.$log.error('check', layout_image)
        //   if (!layout_image) {
        //       throw 'layour image not found'
        //   }
        return `url('${layout_image}')`;
      },
      elements() {
        if (
          this.antragDruckTemplate !== null &&
          this.antragDruckTemplate.pages[this.page_id] !== undefined
        ) {
          /* this.$log.debug('elements:', this.antragDruckTemplate) */
          // return this.antragDruckTemplate.pages[this.page_id].elements.reverse()
          return this.antragDruckTemplate.pages[this.page_id].elements;
        } else {
          return [];
        }
      },
      elementStyle(index) {
        if (this.elements[index].style) {
          return this.elements[index].style;
        } else {
          return {};
        }
      },
      flowUrl() {
        let flowUrl = "http://locflow.waizmanntabelle.de/";
        if (window.location.hostname == "druck.waizmanntabelle.de") {
          flowUrl = "http://flow.waizmanntabelle.de/";
        }
        return flowUrl;
      },
      storageUrl() {
        var storageUrl = "";
        // // You can use your own logic to set your local or production domain
        if (
          window.location.hostname == "localhost" ||
          window.location.hostname == "loc-druck.waizmanntabelle.de"
        ) {
          storageUrl = "https://loc-druck.waizmanntabelle.de/";
        }

        if (window.location.hostname == "druck.waizmanntabelle.de") {
          storageUrl = "https://druck.waizmanntabelle.de/";
        }
        return storageUrl;
      },
      wtbApiUrl() {
        var apiUrl = "";
        // // You can use your own logic to set your local or production domain
        if (
          window.location.hostname == "localhost" ||
          window.location.hostname == "loc-druck.waizmanntabelle.de"
        ) {
          apiUrl = "http://loc.waizmanntabelle.de/api/v2/";
        }

        if (window.location.hostname == "druck.waizmanntabelle.de") {
          apiUrl = "https://www.waizmanntabelle.de/api/v2/";
        }
        return apiUrl;
      },
      apiUrl() {
        var apiUrl = "";
        // // You can use your own logic to set your local or production domain
        if (
          window.location.hostname == "localhost" ||
          window.location.hostname == "loc-druck.waizmanntabelle.de"
        ) {
          apiUrl = "https://loc-druck.waizmanntabelle.de/api/v2/";
        }

        if (window.location.hostname == "druck.waizmanntabelle.de") {
          apiUrl = "https://druck.waizmanntabelle.de/api/v2/";
        }
        return apiUrl;
      },
    }, // end computed
  };
</script>

<style>
  h3 {
    color: rgba(0, 0, 0, 0.7);
  }
  .element {
    border: 1px solid grey;
    /* min-width: 30em; */
    /* background: grey; */
    opacity: 0.7;
  }
  .antrag_druck_template_generator_wrapper {
    /* font-family: "Courier New", Courier, monospace; */
    /* font-family: "Dejavu Sans", Dejavu, monospace; */
    font-family: "FreeMono", monospace;
    font-size: 16px;
    padding-top: 2em;
  }
  .pdf-container {
    min-width: 1240px;
    /* height: 1754px; */
    border: 1px solid green;
    overflow: scroll;
    /* background-size: 1240px 1754px; */
    overflow: hidden;
  }
  .control-element-selected-card {
    background-color: rgba(200, 200, 200, 0.4);
    /* box-shadow: 2px 2px; */
  }
  .element-selected {
    background-color: blue;
    color: white;
    /* box-shadow: 2px 2px; */
  }
  .function-selected {
    background-color: rgb(200, 200, 200);
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }
  .hidden-highlight {
    background-color: red;
    opacity: 0.8;
  }
</style>
