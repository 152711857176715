import axios from "axios";

/* let backendHost = process.env.NODE_ENV === 'production'
  ?'https://www.waizmanntabelle.de'
  :'http://loc.waizmanntabelle.de'

const baseURL = `${backendHost}/api/v2`; */

var apiUrl = "https://druck.waizmanntabelle.de/api/v2";
// // You can use your own logic to set your local or production domain
if (window.location.hostname == "localhost") {
  apiUrl = "https://loc-druck.waizmanntabelle.de/api/v2";
}

if (window.location.hostname == "loc-druck.waizmanntabelle.de") {
  apiUrl = "https://loc-druck.waizmanntabelle.de/api/v2";
}

if (window.location.hostname == "druck.waizmanntabelle.de") {
  apiUrl = "https://druck.waizmanntabelle.de/api/v2";
}

const baseDomain = apiUrl;

// // The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = `${baseDomain}`;

export default axios.create({
  baseURL: baseURL,
  withCredentials: true,
});
