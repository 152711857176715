import GuestRepository from "./GuestRepository";
import AuthRepository from "./AuthRepository";
import AntragDruckRepository from "./AntragDruckRepository";
import VueberechnungRepository from "./VueberechnungRepository";
import DokumenteRepository from "./DokumenteRepository";
import TarifRepositoryV2 from "./TarifRepositoryV2";


const repositories = {
  guest: GuestRepository,
  auth: AuthRepository,
  antragdruck: AntragDruckRepository,
  vueberechnung: VueberechnungRepository,
  dokumente: DokumenteRepository,
  tarifV2: TarifRepositoryV2,
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
