import WtbRepository from "./WtbRepository";
import Repository from "./Repository";
//WtbRepository.get(`${resource}/abschluss/${abs_id}
const resource = "/antragapi";

export default {
  getVersicherungsbeginn(versicherungsbeginnData) {
    return WtbRepository.post(
      `${resource}/versicherungsbeginn`,
      versicherungsbeginnData
    );
  },
  getEintrittsalter(eintrittsalterData) {
    return WtbRepository.post(`${resource}/eintrittsalter`, eintrittsalterData);
  },
  // getEintrittsalter(eintrittsalterData) {
  //   return Repository.get(`https://api.pro.de/tarif`, eintrittsalterData);
  // },
  getPreisEintrittsalterO(tarif_id, ber_id, eintrittsalter) {
    return WtbRepository.get(
      `${resource}/preis_eintrittsalter/${tarif_id}/${ber_id}/${eintrittsalter}`
    );
  },
  getPreisEintrittsalter(tarif_id, ber_id) {
    let select = {};
    select.tarif_id = tarif_id;
    select.ber_id = ber_id;
    select.action = "getTarifMitGeburtstag";
    return Repository.post(`https://api.waizmannpro.de/tarif`, select);
  },
  getPreisSimple(abs_ber_id, tarif_id, abs_beginn_plus) {
    return WtbRepository.get(
      `${resource}/preis/${abs_ber_id}/${tarif_id}/${abs_beginn_plus}/7827389217`
    );
  },
  getWtbTarife() {
    // return WtbRepository.get(`${resource}/wtb_tarife`);
    let select = {};
    select.action = "getAlleZusatzTarife";
    return Repository.post(`https://api.waizmannpro.de/tarif`, select);
  },
  getTarifFragen(tarif_id) {
    let select = {};
    select.tarif_id = tarif_id;
    select.action = "getAntragFragenPlain";
    return Repository.post(`https://api.waizmannpro.de/tarif`, select);
  },
  getTarifFragenO(tarif_id) {
    return WtbRepository.get(`${resource}/tarif_fragen/${tarif_id}`);
  },
  getKunde(aid, tarif_id) {
    return WtbRepository.get(`${resource}/kunde/${aid}/${tarif_id}`);
  },
  getTarif(tarif_id) {
    let select = {};
    select.tarif_id = tarif_id;
    select.action = "getTarifBeschreibungIntern";
    return Repository.post(`https://api.waizmannpro.de/tarif`, select);
  },
  getTarifO(tarif_id) {
    return WtbRepository.get(`${resource}/tarif/${tarif_id}`);
  },

  getAbschluss(abs_id) {
    // if to www.wtb.de -> abschlusslock has to be push/merged
    // return WtbRepository.get(`${resource}/abschlusslock/${abs_id}/${token}`);
    return WtbRepository.get(`${resource}/abschluss/${abs_id}`);
  },
  getLastAbschlussFromTarif(tarif_id) {
    return WtbRepository.get(
      `${resource}/last_abschluss_from_tarif/${tarif_id}`
    );
  },
  getBearbeiter(bearbeiter_id) {
    return WtbRepository.get(`${resource}/bearbeiter/${bearbeiter_id}`);
  },
};
