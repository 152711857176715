import Repository from "./Repository";

const resource = "/antragdruckapi";
export default {
  // //////////////////////
  // Funcs to remove
  // Moved to Florian API
  // //////////////////////////
  // getPreis(aid, tarif_id) {
  //   return Repository.get(`${resource}/preis/${aid}/${tarif_id}`);
  // },
  // getTarifFragen(tarif_id) {
  //   return Repository.get(`${resource}/tarif_fragen/${tarif_id}`);
  // },
  // getKunde(aid, tarif_id) {
  //   return Repository.get(`${resource}/kunde/${aid}/${tarif_id}`);
  // },
  // getAbschluss(abs_id) {
  //   return Repository.get(`${resource}/abschluss/${abs_id}`);
  // },
  // getLastAbschlussFromTarif(tarif_id) {
  //   return Repository.get(`${resource}/last_abschluss_from_tarif/${tarif_id}`);
  // },
  // getBearbeiter(bearbeiter_id) {
  //   return Repository.get(`${resource}/bearbeiter/${bearbeiter_id}`);
  // },
  // ///////////////////
  // API OK
  // ///////////////////
  getAbschlussIntern(abs_id) {
    // if to www.wtb.de -> abschlusslock has to be push/merged
    // return WtbRepository.get(`${resource}/abschlusslock/${abs_id}/${token}`);
    return Repository.get(`${resource}/abschluss/${abs_id}`);
  },
  getBearbeiterIntern() {
    return Repository.get(`${resource}/bearbeiter/3`);
  },
  getKundeIntern(aid, tarif_id) {
    return Repository.get(`${resource}/kunde/${aid}/${tarif_id}`);
  },
  getLastAbschlussFromTarifIntern(tarif_id) {
    return Repository.get(`${resource}/last_abschluss_from_tarif/${tarif_id}`);
  },
  getErgoAntrag(tarif_id, abs_id) {
    return Repository.get(`${resource}/ergo_webservice/${tarif_id}/${abs_id}`);
  },
  checkLogin() {
    return Repository.get(`${resource}/checklogin`);
  },
  checktoken() {
    return Repository.get(`${resource}/checktoken`);
  },
  getAdtgTarife() {
    return Repository.get(`${resource}/adtg_tarife`);
  },
  getAntragImagesList(tarif_id) {
    return Repository.get(`${resource}/antrag_images_list/${tarif_id}`);
  },
  markAntragAsGenerated(abs_id) {
    return Repository.get(`${resource}/mark_antrag_as_generated/${abs_id}`);
  },
  getAntragDruckTemplate(tarif_id) {
    return Repository.get(`${resource}/antrag_druck_template/${tarif_id}`);
  },
  uploadPdfFile(tarif_id, file) {
    return Repository.post(`${resource}/upload_pdf_file/${tarif_id}`, {
      file: file,
    });
  },
  getAntragDruckLayoutImage(tarif_id, image) {
    return Repository.post(
      `${resource}/antrag_druck_layout_image/${tarif_id}`,
      { image: image }
    );
  },
  saveAntragDruckTemplate(tarif_id, template) {
    return Repository.post(`${resource}/antrag_druck_template/${tarif_id}`, {
      template,
    });
  },
  updateAdtgTarif(adtgTarif) {
    return Repository.post(`${resource}/update_adtg_tarif`, {
      adtgTarif: adtgTarif,
    });
  },
  addAdtgTarif(adtgTarif) {
    return Repository.post(`${resource}/add_adtg_tarif`, {
      adtgTarif: adtgTarif,
    });
  },
  cropImage(cropData) {
    return Repository.post(`${resource}/crop_image`, { cropData: cropData });
  },
  getFlowImage(image) {
    return Repository.get(`${resource}/flow_image/?image=${image}`);
  },
  generateFileAnschreiben(tarif_id, abs_id) {
    return Repository.get(
      `${resource}/generate_anschreiben_pdf/file/${tarif_id}/${abs_id}`
    );
  },
  generateFileAntrag(tarif_id, abs_id) {
    return Repository.get(
      `${resource}/generate_antrag_pdf/file/${tarif_id}/${abs_id}`
    );
  },
  generateFileClearment(tarif_id, abs_id) {
    return Repository.get(
      `${resource}/generate_clearment_pdf/file/${tarif_id}/${abs_id}`
    );
  },
  generateFileHinweis(tarif_id, abs_id) {
    return Repository.get(
      `${resource}/generate_hinweis_pdf/file/${tarif_id}/${abs_id}`
    );
  },
  generateFileTarifbeschreibung(tarif_id, abs_id) {
    return Repository.get(
      `${resource}/generate_tarifbeschreibung_pdf/file/${tarif_id}/${abs_id}`
    );
  },
  generateAntragAndBeiblaetter(abs_id) {
    return Repository.get(
      `${resource}/generate_antrag_and_beiblaetter_pdf/${abs_id}`
    );
  },
  getImage(image) {
    return Repository.get(`${resource}/image/?image=${image}`);
  },
  restoreHistory(tarif_id, file) {
    return Repository.get(`${resource}/restore_history/${tarif_id}/${file}`);
  },
  getHistoryList(tarif_id) {
    return Repository.get(`${resource}/history_list/${tarif_id}`);
  },
  createAntragDruckTemplate(tarif_id) {
    return Repository.get(
      `${resource}/create_antrag_druck_template/${tarif_id}`
    );
  },
};
