import Repository from "./Repository";

const resource = "/tarifapi";
export default {
  getAllTarifs() {
    return Repository.get(`${resource}/all_tarifs`);
  },
  // get(tarif_id) {
  //   return Repository.get(`${resource}/tarif/${tarif_id}`);
  // }
};


