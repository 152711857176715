<template>
    <v-container>
        <antrag-druck-template-generator style="margin-top: 3em" />
    </v-container>
</template>

<script>
import AntragDruckTemplateGenerator from "../components/AntragDruckTemplateGenerator/AntragDruckTemplateGenerator";

export default {
    name: "antrag-druck-template-generator-page",
    components: { AntragDruckTemplateGenerator },
    data() {
        return {};
    },
    methods: {},
};
</script> 

<style scoped>
</style>
 


