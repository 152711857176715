// import Vue from 'vue'
// import Vuex from 'vuex'

import { RepositoryFactory } from "../repositories/RepositoryFactory";

// const berechnungRepository = RepositoryFactory.get('berechnung')
// const authRepository = RepositoryFactory.get('auth')
const antragDruckRepository = RepositoryFactory.get("antragdruck");
const vueberechnungRepository = RepositoryFactory.get("vueberechnung");
const dokumenteRepository = RepositoryFactory.get("dokumente");

const tarifRepositoryV2 = RepositoryFactory.get("tarifV2");

// const ApiErrorType = ['Warining', 'Error', 'Critical'];
// class ApiError {
//     constructor (errorType = 0, message = 'Default ApiError') {
//         this.type = errorType
//         this.message = message
//     }
// }

// Vue.use(Vuex)
// export default new Vuex.Store({
const antragdruck = {
  namespaced: true,
  state: {
    historyList: [],
    tarifFragen: [],
    lastAbschlussList: [],
    wtbTarife: {
      tarife: [],
    },
    ANTRAG_GENERATED: false,
    generated: {
      antrag: {
        filename: null,
        status: null,
      },
      anschreiben: {
        filename: null,
        status: null,
      },
      clearment: {
        filename: null,
        status: null,
      },
      hinweis: {
        filename: null,
        status: null,
      },
      tarifbeschreibung: {
        filename: null,
        status: null,
      },
    },
    antragDruckTemplate: {
      tarif_id: null,
      pages: [],
    },
    antragDruckLayoutImage: {
      image: null,
      width: 0,
      height: 0,
    },
    antragImagesList: [],
    antragData: {
      abschluss: null,
      bearbeiter: null,
      kunde: null,
      tarif: null,
      preis: {
        preis_value: "22.10",
      },
      preisNeu: null,
      preiseList: [],
      versicherungsbeginn: "16.10.2022",
    },
    eintrittsalter: null,
    variableKeys: {},
    adtgTarife: [],
    tarif: null,
    images: {},
    letzteBerechnung: false,
    // integer value (count running queries)
    loading_status: 0,
    status: "",
    errors: [],
  },
  mutations: {
    resetTemplate(state) {
      state.antragDruckTemplate = {
        tarif_id: null,
        pages: [],
      };
    },
    resetGenerated(state) {
      state.generated = {
        antrag: {
          filename: null,
          status: null,
        },
        anschreiben: {
          filename: null,
          status: null,
        },
        clearment: {
          filename: null,
          status: null,
        },
        hinweis: {
          filename: null,
          status: null,
        },
        tarifbeschreibung: {
          filename: null,
          status: null,
        },
      };
    },
    changeLayoutOrientation(state, { page_id, value }) {
      state.antragDruckTemplate.pages[page_id].layout_landscape_orientation =
        value;
    },
    antragMarkedAsGenerated(state, abschluss) {
      state.abschluss = abschluss;
      state.ANTRAG_GENERATED = true;
    },
    setTarifFragen(state, fragen) {
      state.tarifFragen = fragen;
    },
    setHistoryList(state, list) {
      state.historyList = list;
    },
    setLastAbschlussList(state, lastAbschlussList) {
      state.lastAbschlussList = lastAbschlussList;
    },
    setKunde(state, kunde) {
      state.antragData.kunde = kunde;
      state.variableKeys.kunde = Object.keys(kunde);
    },
    setAntragImagesList(state, antragImagesList) {
      state.antragImagesList = antragImagesList;
    },
    setAntragDruckTemplate(state, antragDruckTemplate) {
      state.antragDruckTemplate = antragDruckTemplate;
      state.antragDruckLayoutImage = {
        image: null,
        width: 0,
        height: 0,
      };
    },
    setPreis(state, preiseList) {
      // Initialisierung des Objekts `preis` direkt mit der Variable `preiseList`
      let preis = preiseList;

      // Definition der Felder, die bearbeitet werden sollen
      const fields = [
        "zuschlag",
        "preis_plus_zuschlag",
        "preis_value",
        "preis_baustein1",
        "preis_baustein2",
        "preis_baustein3",
      ];

      // Durchlaufen der Felder und Anfügen der Währungseinheit "€", wenn das Feld im `preis` Objekt existiert
      fields.forEach((field) => {
        if (preis[field]) {
          preis[`${field}_euro`] = `${preis[field]} €`;
        }
      });
      //state.antragData.preis = preis;
      //state.variableKeys.preis = Object.keys(state.antragData.preis);

      if (preiseList.preisZukunft != "") {
        let preisNeu = {};
        preisNeu.preis_value = preiseList.preisZukunft;
        preisNeu.preis_value_euro = preiseList.preisZukunft + "€";
        //state.antragData.preisNeu = preisNeu;
        // state.variableKeys.preisNeu = Object.keys(state.antragData.preis);
      }

      // state.preis = preiseList; // Ändern des Zustands
    },
    setPreisO(state, preiseList) {
      let fields = [
        "zuschlag",
        "preis_plus_zuschlag",
        "preis_value",
        "preis_baustein1",
        "preis_baustein2",
        "preis_baustein3",
      ];

      state.antragData.preiseList = preiseList;
      let preis = preiseList.find((preis) => preis.preis_status == "1");
      if (preis) {
        fields.forEach((field) => {
          if (preis[field]) {
            preis[`${field}_euro`] = preis[field] + " €";
          }
        });
      }
      if (!preis) {
        preis = {
          preis_id: "0",
          preis_value: null,
          preis_checked: null,
          preis_baustein1: null,
          preis_baustein2: null,
          preis_baustein3: null,
          preis_wtb_id: null,
          preis_status: null,
          preis_created_date: null,
          preis_alter: null,
          preis_ended_date: null,
          zuschlag: null,
          preis_plus_zuschlag: null,
        };

        // throw "Preis with preis_status == 1  not found! " + JSON.stringify(preiseList)
      }
      state.antragData.preis = preis;
      state.variableKeys.preis = Object.keys(state.antragData.preis);

      let preisNeu = preiseList.find((preis) => preis.preis_status == 3);
      if (preisNeu) {
        fields.forEach((field) => {
          if (preisNeu[field]) {
            preisNeu[`${field}_euro`] = preis[field] + " €";
          }
        });
        state.antragData.preisNeu = preisNeu;
      } else {
        // template Generator needs keys to be setted
        preisNeu = {};
        Object.keys(preis).forEach((key) => (preisNeu[key] = null));
        state.antragData.preisNeu = preisNeu;
      }
      state.variableKeys.preisNeu = Object.keys(state.antragData.preis);
    },
    setEintrittsalter(state, data) {
      state.eintrittsalter = data;
    },
    setAntragDruckLayoutImage(state, antragDruckLayoutImage) {
      state.antragDruckLayoutImage = antragDruckLayoutImage;
    },
    addImage(state, data) {
      state.images[`image-${data.element_index}`] = data.image;
    },
    updateComment(state, comment) {
      state.antragDruckTemplate.comment = comment;
    },
    addElement(state, { page_id, element }) {
      state.antragDruckTemplate.pages[page_id].elements.push(element);
    },
    addPage(state) {
      let newPage = {
        layout_image: "",
        elements: [],
        druck_copie_only: true,
        layout_landscape_orientation: false,
      };
      state.antragDruckTemplate.pages.push(newPage);
    },
    deletePage(state, page_id) {
      state.antragDruckTemplate.pages.splice(page_id, 1);
    },
    resetImages(state) {
      state.images = [];
    },
    setBearbeiter(state, bearbeiter) {
      state.antragData.bearbeiter = bearbeiter;
      state.variableKeys.bearbeiter = Object.keys(bearbeiter);
    },
    setTarif(state, tarif) {
      state.antragData.tarif = tarif;
      state.variableKeys.tarif = Object.keys(tarif);
    },
    setAbschluss(state, abschluss) {
      state.antragData.abschluss = abschluss;
      state.variableKeys.abschluss = Object.keys(abschluss);
    },
    setVersicherungsbeginn(state, data) {
      // const options = {
      //     year: 'numeric', month: '2-digit', day: '2-digit'
      // };
      // state.antragData.versicherungsbeginn = new Date(versicherungsbeginn.date).
      //     toLocaleDateString('de-DE', options)
      state.antragData.versicherungsbeginn = data;
      state.variableKeys.versicherungsbeginn = Object.keys(data);
    },
    updateElement(state, { page_id, index, element }) {
      // console.log(element)
      // Vue.set(state.antragDruckTemplate)
      // state.antragDruckTemplate.pages[page_id].elements[index].condition = element.condition
      for (let index in element.style) {
        if (element.style[index] == "") {
          delete element.style[index];
        }
      }
      // if (element.style && element.style['font-size'] && typeof element.style['font-size'] == "string") {
      //   element.style['font-size'] = parseInt(element.style['font-size'].remove("px"))
      // }
      state.antragDruckTemplate.pages[page_id].elements[index] = element;
    },
    updateLayoutImage(state, { page_id, image }) {
      // console.log(element)
      // Vue.set(state.antragDruckTemplate)
      // state.antragDruckTemplate.pages[page_id].elements[index].condition = element.condition
      state.antragDruckTemplate.pages[page_id].layout_image = image;
    },
    updatePagePrintSettings(state, { page_id, print_settings }) {
      state.antragDruckTemplate.pages[page_id].print_settings = print_settings;
    },
    updateDefaultFontSize(state, { page_id, default_font_size }) {
      // console.log(element)
      // Vue.set(state.antragDruckTemplate)
      // state.antragDruckTemplate.pages[page_id].elements[index].condition = element.condition
      state.antragDruckTemplate.pages[page_id].default_font_size =
        default_font_size;
    },
    antragGenerated(state, data) {
      state.generated.antrag = data;
    },
    anschreibenGenerated(state, data) {
      state.generated.anschreiben = data;
    },
    clearmentGenerated(state, data) {
      state.generated.clearment = data;
    },
    hinweisGenerated(state, data) {
      state.generated.hinweis = data;
    },
    tarifbeschreibungGenerated(state, data) {
      state.generated.tarifbeschreibung = data;
    },
    start_loading(state) {
      state.loading_status += 1;
      state.status = "Start Loading";
    },
    stop_loading(state) {
      state.loading_status -= 1;
      state.status = "loaded";
    },
    success(state, status) {
      state.status = status;
    },
    error(state, error) {
      state.errors.push(error);
    },
    setAdtgTarife(state, adtgTarife) {
      state.adtgTarife = adtgTarife;
    },
    setWtbTarife(state, wtbTarife) {
      state.wtbTarife.tarife = wtbTarife.tarife.map((tarif) => {
        tarif.tarif_name_combined = `${tarif.tarif_id}: ${tarif.tarif_name_wtb}`;
        return tarif;
      });
    },
    resetErrors(state) {
      state.errors = [];
    },
  },
  actions: {
    checkLogin({ commit }) {
      return antragDruckRepository
        .checkLogin()
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        });
      // .finally(() => {commit('stop_loading')});
    },
    resetTemplate({ commit }) {
      // commit('resetTemplate')
      commit("resetErrors");
    },
    resetErrors({ commit }) {
      commit("resetErrors");
    },
    resetGenerated({ commit }) {
      commit("resetGenerated");
    },
    getWtbTarife({ commit }) {
      commit("start_loading");
      return vueberechnungRepository
        .getWtbTarife()
        .then((resp) => {
          commit("success");
          commit("setWtbTarife", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getErgoAntrag({ commit }, { tarif_id, abs_id }) {
      commit("start_loading");
      return antragDruckRepository
        .getErgoAntrag(tarif_id, abs_id)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getAdtgTarife({ commit }) {
      commit("start_loading");
      return antragDruckRepository
        .getAdtgTarife()
        .then((resp) => {
          commit("success");
          commit("setAdtgTarife", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    changeLayoutOrientation({ commit }, { page_id, value }) {
      commit("changeLayoutOrientation", { page_id, value });
    },
    updateComment({ commit }, comment) {
      commit("updateComment", comment);
    },
    updateAdtgTarif({ commit }, adtgTarif) {
      commit("start_loading");
      return antragDruckRepository
        .updateAdtgTarif(adtgTarif)
        .then((resp) => {
          commit("success");
          commit("setAdtgTarife", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    addAdtgTarif({ commit }, adtgTarif) {
      commit("start_loading");
      return antragDruckRepository
        .addAdtgTarif(adtgTarif)
        .then((resp) => {
          commit("success");
          commit("setAdtgTarife", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    updateElement({ commit }, { page_id, index, element }) {
      commit("updateElement", { page_id, index, element });
    },
    updateLayoutImage({ commit }, { page_id, image }) {
      commit("updateLayoutImage", { page_id, image });
    },
    updateDefaultFontSize({ commit }, { page_id, default_font_size }) {
      commit("updateDefaultFontSize", { page_id, default_font_size });
    },
    updatePagePrintSettings({ commit }, { page_id, print_settings }) {
      commit("updatePagePrintSettings", { page_id, print_settings });
    },
    getKunde({ commit }, { aid, tarif_id }) {
      commit("start_loading");
      return vueberechnungRepository
        .getKunde(aid, tarif_id)
        .then((resp) => {
          commit("success");
          commit("setKunde", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getKundeIntern({ commit }, { aid, tarif_id }) {
      commit("start_loading");
      return antragDruckRepository
        .getKundeIntern(aid, tarif_id)
        .then((resp) => {
          commit("success");
          commit("setKunde", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getAntragImagesList({ commit }, tarif_id) {
      commit("start_loading");
      return antragDruckRepository
        .getAntragImagesList(tarif_id)
        .then((resp) => {
          commit("success");
          commit("setAntragImagesList", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getLastAbschlussFromTarifIntern({ commit }, tarif_id) {
      commit("start_loading");
      return antragDruckRepository
        .getLastAbschlussFromTarifIntern(tarif_id)
        .then((resp) => {
          commit("success");
          commit("setLastAbschlussList", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    //deleteIntern
    getLastAbschlussFromTarif({ commit }, tarif_id) {
      commit("start_loading");
      return vueberechnungRepository
        .getLastAbschlussFromTarif(tarif_id)
        .then((resp) => {
          commit("success");
          commit("setLastAbschlussList", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getVersicherungsbeginn({ commit }, versicherungsbeginnData) {
      commit("start_loading");
      return vueberechnungRepository
        .getVersicherungsbeginn(versicherungsbeginnData)
        .then((resp) => {
          commit("success");
          commit("setVersicherungsbeginn", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    checkGetLoginToken() {
      return antragDruckRepository.checktoken().then((resp) => {
        if (resp.data) {
          return resp.data;
        }
      });
    },
    getAbschluss({ commit }, { abs_id, token }) {
      commit("start_loading");
      return vueberechnungRepository
        .getAbschluss(abs_id, token)
        .then((resp) => {
          commit("success");
          commit("setAbschluss", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getAbschlussIntern({ commit }, abs_id) {
      commit("start_loading");
      return antragDruckRepository
        .getAbschlussIntern(abs_id)
        .then((resp) => {
          commit("success");
          commit("setAbschluss", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getAntragDruckTemplate({ commit }, tarif_id) {
      commit("start_loading");
      return antragDruckRepository
        .getAntragDruckTemplate(tarif_id)
        .then((resp) => {
          commit("success");
          commit("setAntragDruckTemplate", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    saveAntragDruckTemplate({ commit }, { tarif_id, template }) {
      commit("start_loading");
      return antragDruckRepository
        .saveAntragDruckTemplate(tarif_id, template)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    createAntragDruckTemplate({ commit }, tarif_id) {
      commit("start_loading");
      return antragDruckRepository
        .createAntragDruckTemplate(tarif_id)
        .then((resp) => {
          commit("success");
          // commit('antragDruckTemplateCreated', resp.data)
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    uploadPdfFile({ commit }, { tarif_id, file }) {
      commit("start_loading");
      return antragDruckRepository
        .uploadPdfFile(tarif_id, file)
        .then((resp) => {
          commit("success");
          // commit('setAntragDruckLayoutImage', resp.data)
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    cropImage({ commit }, cropData) {
      commit("start_loading");
      return antragDruckRepository
        .cropImage(cropData)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getAntragDruckLayoutImage({ commit }, { tarif_id, image }) {
      commit("start_loading");
      return antragDruckRepository
        .getAntragDruckLayoutImage(tarif_id, image)
        .then((resp) => {
          commit("success");
          commit("setAntragDruckLayoutImage", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    addElement({ commit }, { page_id, element }) {
      commit("addElement", { page_id: page_id, element: element });
    },
    addPage({ commit }) {
      commit("addPage");
    },
    deletePage({ commit }, page_id) {
      commit("deletePage", page_id);
    },
    getFlowImage({ commit }, { image, element_index }) {
      commit("start_loading");
      return antragDruckRepository
        .getFlowImage(image)
        .then((resp) => {
          commit("success");
          commit("addImage", {
            image: resp.data,
            element_index: element_index,
          });
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getImage({ commit }, { image, element_index }) {
      commit("start_loading");
      return antragDruckRepository
        .getImage(image)
        .then((resp) => {
          commit("success");
          commit("addImage", {
            image: resp.data,
            element_index: element_index,
          });
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    resetImages({ commit }) {
      commit("resetImages");
    },
    getAllTarifs({ commit }) {
      commit("start_loading");
      return tarifRepositoryV2
        .getAllTarifs()
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getTarif({ commit }, tarif_id) {
      commit("start_loading");
      return vueberechnungRepository
        .getTarif(tarif_id)
        .then((resp) => {
          commit("success");
          commit("setTarif", resp.data.tarif);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getBearbeiter({ commit }, bearbeiter_id) {
      commit("start_loading");
      return vueberechnungRepository
        .getBearbeiter(bearbeiter_id)
        .then((resp) => {
          commit("success");
          commit("setBearbeiter", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getBearbeiterIntern({ commit }, bearbeiter_id) {
      commit("start_loading");
      return antragDruckRepository
        .getBearbeiterIntern(bearbeiter_id)
        .then((resp) => {
          commit("success");
          commit("setBearbeiter", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getHistoryList({ commit }, tarif_id) {
      commit("start_loading");
      return antragDruckRepository
        .getHistoryList(tarif_id)
        .then((resp) => {
          commit("success");
          commit("setHistoryList", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getTarifFragen({ commit }, tarif_id) {
      commit("start_loading");
      return vueberechnungRepository
        .getTarifFragen(tarif_id)
        .then((resp) => {
          commit("success");
          commit("setTarifFragen", resp.data.antragfragen);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    restoreHistory({ commit }, { tarif_id, file }) {
      commit("start_loading");
      return antragDruckRepository
        .restoreHistory(tarif_id, file)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getEintrittsalter({ commit }, eintrittsalterData) {
      commit("start_loading");
      // return antragDruckRepository.getPreis(aid, tarif_id)
      return vueberechnungRepository
        .getEintrittsalter(eintrittsalterData)
        .then((resp) => {
          commit("success");
          commit("setEintrittsalter", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    getPreisEintrittsalter({ commit }, { tarif_id, ber_id, eintrittsalter }) {
      commit("start_loading");
      // return antragDruckRepository.getPreis(aid, tarif_id)
      return vueberechnungRepository
        .getPreisEintrittsalter(tarif_id, ber_id, eintrittsalter)
        .then((resp) => {
          commit("success");
          commit("setPreis", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    // getPreis({commit}, {aid, tarif_id}) {
    // getPreis ({ commit }, { abs_ber_id, tarif_id, abs_beginn_plus }) {
    //     commit('start_loading')
    //     // return antragDruckRepository.getPreis(aid, tarif_id)
    //     return vueberechnungRepository.getPreis(abs_ber_id, tarif_id, abs_beginn_plus)
    //         .then(resp => {
    //             commit('success')
    //             commit('setPreis', resp.data)
    //             return resp;
    //         })
    //         .catch(err => {
    //             commit('error', new ApiError(0, `${err.response.data.error}`))
    //             return err;
    //         }).finally(() => { commit('stop_loading') });
    // },
    generateFileAntrag({ commit }, { tarif_id, abs_id }) {
      commit("start_loading");
      return antragDruckRepository
        .generateFileAntrag(tarif_id, abs_id)
        .then((resp) => {
          commit("success");
          commit("antragGenerated", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    generateFileAnschreiben({ commit }, abs_id) {
      commit("start_loading");
      return dokumenteRepository
        .generatePdf(abs_id, "anschreiben_zahn")
        .then((resp) => {
          commit("success");
          commit("anschreibenGenerated", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    generateFileClearment({ commit }, abs_id) {
      commit("start_loading");
      return dokumenteRepository
        .generatePdf(abs_id, "clearment")
        .then((resp) => {
          commit("success");
          commit("clearmentGenerated", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    generateFileHinweis({ commit }, abs_id) {
      commit("start_loading");
      return dokumenteRepository
        .generatePdf(abs_id, "antraghinweise_zahn")
        .then((resp) => {
          commit("success");
          commit("hinweisGenerated", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    generateFileTarifbeschreibung({ commit }, abs_id) {
      commit("start_loading");
      return dokumenteRepository
        .generatePdf(abs_id, "tarifbeschreibung_zahn")
        .then((resp) => {
          commit("success");
          commit("tarifbeschreibungGenerated", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
    markAntragAsGenerated({ commit }, { abs_id }) {
      commit("start_loading");
      return antragDruckRepository
        .markAntragAsGenerated(abs_id)
        .then((resp) => {
          commit("success");
          commit("antragMarkedAsGenerated", resp.data);
          return resp;
        })
        .catch((err) => {
          commit("error", err);
          return err;
        })
        .finally(() => {
          commit("stop_loading");
        });
    },
  },
  getters: {
    kunde: (state) => state.antragData.kunde,
    tarif: (state) => state.antragData.tarif,
    abschluss: (state) => state.antragData.abschluss,
    bearbeiter: (state) => state.antragData.bearbeiter,
    antragDruckTemplate: (state) => state.antragDruckTemplate,
    antragDruckLayoutImage: (state) => state.antragDruckLayoutImage,
    loading_status: (state) => state.loading_status,
    isLoading: (state) => state.loading_status > 0,
    status: (state) => state.status,
    errors: (state) => state.errors,
    antragData: (state) => state.antragData,
    images: (state) => state.images,
    variableKeys: (state) => state.variableKeys,
    tarifFragen: (state) => state.tarifFragen,
    lastAbschluss: (state) => state.lastAbschlussList,
    antragImagesList: (state) => state.antragImagesList,
    historyList: (state) => state.historyList,
    generated: (state) => state.generated,
    adtgTarife: (state) => state.adtgTarife,
    wtbTarife: (state) => state.wtbTarife,
    eintrittsalter: (state) => state.eintrittsalter,
    versicherungsbeginn: (state) => state.antragData.versicherungsbeginn,
  },
};

export default antragdruck;
