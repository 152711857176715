import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import antragdruck from './store/antragdruck';
import auth from './store/auth';

export default new Vuex.Store({
  modules: {
    antragdruck: antragdruck,
    auth: auth
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  }
});
