<template>
<v-container>
    <h1>Testing Prerender</h1>
    <v-card>Simple card</v-card>
    <div v-for="(val, index) in simpleList" :key="index">
        {{ val }}
    </div>
</v-container>
</template>

<script>
export default {
   
  name: "prerender-spa-page",
  components: {},
  created() {
    document.querySelector('meta[name="description"]').setAttribute("content", 'Valverde');
    this.$log.debug('prerender-spa-page')

    // document.dispatchEvent(new Event("x-app-rendered"))
  },
  data: function() {
    return {
      simpleList: ['one', 'two', 'three'],
      selectVal: '1',
      radios: 'radio-1',
      checkbox: true
    }
  },
};
</script>

<style scoped>
</style>

