import PrerenderSpaPage from './components/PrerenderSpaPage'
import AntragDruckTemplateGeneratorPage from './pages/AntragDruckTemplateGeneratorPage'
import TarifeManagerPage from './pages/TarifeManagerPage'


const routes = [
    {
        path: '/',
        component: AntragDruckTemplateGeneratorPage,
        meta: { guest: true }
    },
    {
        path: '/tarife-manager',
        name: 'tarife_manager',
        component: TarifeManagerPage,
        meta: { guest: true }
    },
    {
        path: '/prerender_spa',
        name: 'prerender_spa',
        component: PrerenderSpaPage,
        meta: { guest: true }
    },
    { path: '*', redirect: '/' }
];


export default routes;