export default class BinaryHelper {

    constructor (status_bit_array_length = 8) {
        this.status_bit_array_length = status_bit_array_length
    }
    integerToBinary (int) {
        let binary = int.toString(2)
        let str = new Array(this.status_bit_array_length + 1).join("0");
        binary = str.substr(binary.length) + binary;
        return binary
    }
    integerToBooleanArray (int) {
        let binaryArray = this.integerToBinary(int).split('')
        let result = binaryArray.map(el => el == '1' ? true : false)
        return result
    }
    booleanArrayToInteger (status) {
        let binary = status.map(el => el ? "1" : "0").join('');
        let num = parseInt(binary, 2)
        return num
    }
}