import Vue from "vue";
import App from "./App.vue";
import store from "./store.js";
//import './registerServiceWorker';

import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

import vuetify from "@/plugins/vuetify";
import VueRouter from "vue-router";
import VueLogger from "vuejs-logger";
import routes from "./routes";

const isProduction = process.env.NODE_ENV === "production";
Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.prototype.$APP_TITLE = process.env.VUE_APP_TITLE;

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, options);
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

let mixin = {
  data: function () {
    return {
      all_data_fetched: false,
      wait_for_data: true,
    };
  },
};

new Vue({
  mixins: [mixin],
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
