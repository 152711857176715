<template>
  <v-app id="app">
    <v-main>
      <router-view v-if="login" />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: "App",
    components: {},

    data() {
      return {
        login: false,
        fullPage: true,
        useSlot: true,
        loaderShape: "dots",
        opacity: 0.9,
        berechnung: false,
        user: false,
        unread: 0,
        guest: false,
        containerHeight: "-10",
      };
    },

    created() {
      //this.checkLogin();
      this.login = true;
    },
    methods: {
      checkLogin() {
        this.$store.dispatch("antragdruck/checkLogin").then((resp) => {
          this.$log.error(resp);
          this.login = resp.data;
          this.$log.debug("Login", this.login);
        });
      },
    },
  };
</script>

<style>
  .logo {
    font-size: 32px;
    text-decoration: none;
    font-weight: bold;
  }
  .logosmall {
    font-size: 24px;
    text-decoration: none;
    font-weight: bold;
  }
  .header_btn_mobil {
    text-transform: none !important;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }
  .header_btn {
    text-transform: none !important;
    font-size: 16px;
  }
  .v-navigation-drawer__border {
    display: none;
  }

  .noUnderline {
    text-decoration: none !important;
  }
</style>
