import Repository from "./Repository";

const resource = "/guestapi";
export default {
    getGuest () {
        return Repository.get(`${resource}/guest`);
    },
    getGuestCookie () {
        return Repository.get(`${resource}/guestcookie`);
    },

    getUser () {
        return Repository.get(`${resource}/user`);
    },
    logout () {
        return Repository.get(`${resource}/logout`);
    },
    getChatOpen () {
        return Repository.get(`${resource}/chatopen`);
    },
    getBestellungen () {
        return Repository.get(`${resource}/bestellungen`);
    },
    getInbox () {
        return Repository.get(`${resource}/inbox`);
    },
    getInboxNew () {
        return Repository.get(`${resource}/inboxcount`);
    },
    getProvTarife () {
        return Repository.get(`${resource}/provtarife`);
    },
    getAbrechnungen () {
        return Repository.get(`${resource}/abrechnungen`);
    },
    getKunden (kundenart) {
        return Repository.get(`${resource}/getkunden/${kundenart}`);
    },
    updateInboxRead (inbox_id) {
        return Repository.get(`${resource}/updateinbox/${inbox_id}`);
    },
    setChatWindowOpen () {
        return Repository.get(`${resource}/chatwindow/open`);
    },
    setChatWindowClose () {
        return Repository.get(`${resource}/chatwindow/close`);
    },
    submitLogin (payload) {
        return Repository.post(`${resource}/login`, { payload });
    },
    submitPwd (payload) {
        return Repository.post(`${resource}/pass`, { payload });
    },
    submitUserUpdate (payload) {
        return Repository.post(`${resource}/updateuser`, { payload });
    },
    submitUserInsert (payload) {
        return Repository.post(`${resource}/insertuser`, { payload });
    },
    submitUserPassUpdate (payload) {
        return Repository.post(`${resource}/updatepass`, { payload });
    },
    submitBestellung (payload) {
        return Repository.post(`${resource}/neuebestellung`, { payload });
    },
    updateGuest (data) {
        return Repository.post(`${resource}/update_guest`, data);
    }
}
